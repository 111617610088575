unit WEBLib.Consts;

interface

resourcestring
  SWarning = 'Warning';
  SError = 'Error';
  SInformation = 'Information';
  SConfirm = 'Confirm';
  SOK = 'OK';
  SCancel = 'Cancel';
  SYes = 'Yes';
  SNo = 'No';
  SAbort = 'Abort';
  SRetry = 'Retry';
  SIgnore = 'Ignore';
  SHelp = 'Help';
  SClose = 'Close';
  SAll = 'All';
  SYesToAll = 'Yes to all';
  SNoToAll = 'No to all';

  SReadError = 'Stream read error';
  SWriteError = 'Stream write error';
  SCannotGetFile = 'Cannot get the file from the URL %s because of the error %d!';

  SErrorLoadFromFile = 'There was an error during the download of the file %s!';
  SLoadFromFileAborted = 'The download of the file %s was aborted!';


resourcestring
  SWarningFR = 'Avertissement';
  SErrorFR = 'Erreur';
  SInformationFR = 'Information';
  SConfirmFR = 'Confirmer';
  SOKFR = 'OK';
  SCancelFR = 'Annuler';
  SYesFR = 'Oui';
  SNoFR = 'Non';
  SAbortFR = 'Interrompre';
  SRetryFR = 'Réessayer';
  SIgnoreFR = 'Ignorer';
  SHelpFR = 'Aide';
  SCloseFR = 'Fermer';
  SAllFR = 'Tout';
  SYesToAllFR = 'Oui à tout';
  SNoToAllFR = 'Non à tout';

resourcestring
  SWarningDE = 'Warnung';
  SErrorDE = 'Fehler';
  SInformationDE = 'Information';
  SConfirmDE = 'Bestätigen';
  SOKDE = 'OK';
  SCancelDE = 'Verzögern';
  SYesDE = 'Ja';
  SNoDE = 'Nein';
  SAbortDE = 'Abbrechen';
  SRetryDE = 'Wiederholen';
  SIgnoreDE = 'Ignorieren';
  SHelpDE = 'Hilfe';
  SCloseDE = 'Schließen';
  SAllDE = 'Alles';
  SYesToAllDE = 'Ja auf Alles';
  SNoToAllDE = 'Nein auf Alles';

resourcestring
  SWarningES = 'Advertencia';
  SErrorES = 'Error';
  SInformationES = 'Información';
  SConfirmES = 'Confirmado';
  SOKES = 'OK';
  SCancelES = 'Cancelar';
  SYesES = 'Sí';
  SNoES = 'No';
  SAbortES = 'Abortar';
  SRetryES = 'Rever';
  SIgnoreES = 'Ignorar';
  SHelpES = 'Ayudar';
  SCloseES = 'Cerrar';
  SAllES = 'Todos';
  SYesToAllES = 'Sí a todo';
  SNoToAllES = 'No a todo';

resourcestring
  SWarningNL = 'Waarschuwing';
  SErrorNL = 'Fout';
  SInformationNL = 'Informatie';
  SConfirmNL = 'Bevestiging';
  SOKNL = 'OK';
  SCancelNL = 'Afbreken';
  SYesNL = 'Ja';
  SNoNL = 'Nee';
  SAbortNL = 'Afbreken';
  SRetryNL = 'Opnieuw';
  SIgnoreNL = 'Negeren';
  SHelpNL = 'Hulp';
  SCloseNL = 'Sluiten';
  SAllNL = 'Alles';
  SYesToAllNL = 'Ja op alles';
  SNoToAllNL = 'Nee op alles';

implementation

end.