unit Unit8;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.ExtCtrls,
  WEBLib.ComCtrls, Vcl.Controls, WEBLib.Grids, Vcl.Imaging.pngimage,
  WEBLib.Buttons, DB, WEBLib.WebCtrls;

type
  TForm7 = class(TForm)
    webControlMain: TPageControl;
    WebPageControlIntroduction: TTabSheet;
    WebImageControl1: TImageControl;
    WebResponsiveGridPanel1: TResponsiveGridPanel;
    WebScrollBox1: TScrollBox;
    WebResponsiveGridPanel2: TResponsiveGridPanel;
    WebMultimediaPlayer1: TMultimediaPlayer;
    WebMultimediaPlayer2: TMultimediaPlayer;
    WebMultimediaPlayer3: TMultimediaPlayer;
    WebMultimediaPlayer4: TMultimediaPlayer;
    WebMultimediaPlayer5: TMultimediaPlayer;
    WebMultimediaPlayer6: TMultimediaPlayer;
    WebMultimediaPlayer7: TMultimediaPlayer;
    WebMultimediaPlayer8: TMultimediaPlayer;
    WebPageControlEnquiry: TTabSheet;
    WebHTMLForm2: THTMLForm;
    WebScrollBox3: TScrollBox;
    WebResponsiveGridPanel5: TResponsiveGridPanel;
    WebLabel2: TLabel;
    webEditFirstName: TEdit;
    webEditSurname: TEdit;
    webEditMobile: TEdit;
    webEditEmail: TEdit;
    webMemoExperience: TMemo;
    WebButton1: TButton;
    WebPanel3: TPanel;
    WebPageControlContacts: TTabSheet;
    WebScrollBox2: TScrollBox;
    WebResponsiveGridPanel4: TResponsiveGridPanel;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebBitBtn3: TBitBtn;
    WebBitBtn4: TBitBtn;
    WebBitBtn1: TBitBtn;
    WebBitBtn2: TBitBtn;
    WebBitBtn5: TBitBtn;
    WebBitBtn6: TBitBtn;
    WebBitBtn7: TBitBtn;
    webPageControlCertification: TTabSheet;
    webPageControlTestimonials: TTabSheet;
    WebScrollBox4: TScrollBox;
    WebResponsiveGridPanel3: TResponsiveGridPanel;
    WebBrowserControl1: TBrowserControl;
    WebScrollBox5: TScrollBox;
    WebResponsiveGridPanelOuterCerts: TResponsiveGridPanel;
    WebBrowserControl2: TBrowserControl;
    WebBrowserControl3: TBrowserControl;
    WebBrowserControl4: TBrowserControl;
    WebBrowserControl5: TBrowserControl;
    WebResponsiveGridPanel6: TResponsiveGridPanel;
    WebLabel6: TLabel;
    WebBrowserControl6: TBrowserControl;
    WebButton2: TButton;
    WebResponsiveGridPanel7: TResponsiveGridPanel;
    WebLabel1: TLabel;
    WebBrowserControl7: TBrowserControl;
    WebButton3: TButton;
    WebResponsiveGridPanel8: TResponsiveGridPanel;
    WebLabel7: TLabel;
    WebBrowserControl8: TBrowserControl;
    WebButton4: TButton;
    WebResponsiveGridPanel9: TResponsiveGridPanel;
    WebLabel8: TLabel;
    WebBrowserControl9: TBrowserControl;
    WebButton5: TButton;
    WebResponsiveGridPanel10: TResponsiveGridPanel;
    WebLabel9: TLabel;
    WebBrowserControl10: TBrowserControl;
    WebButton6: TButton;
    WebResponsiveGridPanel11: TResponsiveGridPanel;
    WebLabel10: TLabel;
    WebBrowserControl11: TBrowserControl;
    WebButton7: TButton;
    WebResponsiveGridPanel12: TResponsiveGridPanel;
    WebLabel11: TLabel;
    WebBrowserControl12: TBrowserControl;
    WebButton8: TButton;
    WebResponsiveGridPanel13: TResponsiveGridPanel;
    WebLabel12: TLabel;
    WebBrowserControl13: TBrowserControl;
    WebButton9: TButton;
    WebResponsiveGridPanel14: TResponsiveGridPanel;
    WebLabel13: TLabel;
    WebBrowserControl14: TBrowserControl;
    WebButton10: TButton;
    WebResponsiveGridPanel15: TResponsiveGridPanel;
    WebLabel14: TLabel;
    WebBrowserControl15: TBrowserControl;
    WebButton11: TButton;
    WebResponsiveGridPanel16: TResponsiveGridPanel;
    WebLabel15: TLabel;
    WebBrowserControl16: TBrowserControl;
    WebButton12: TButton;
    WebResponsiveGridPanel17: TResponsiveGridPanel;
    WebLabel16: TLabel;
    WebBrowserControl17: TBrowserControl;
    WebButton13: TButton;
    WebResponsiveGridPanel18: TResponsiveGridPanel;
    WebLabel17: TLabel;
    WebBrowserControl18: TBrowserControl;
    WebButton14: TButton;
    WebResponsiveGridPanel19: TResponsiveGridPanel;
    WebLabel18: TLabel;
    WebBrowserControl19: TBrowserControl;
    WebButton15: TButton;
    WebResponsiveGridPanel20: TResponsiveGridPanel;
    WebLabel19: TLabel;
    WebBrowserControl20: TBrowserControl;
    WebButton16: TButton;
    procedure WebFormCreate(Sender: TObject);
    procedure WebHTMLForm2Submit(Sender: TObject);
    procedure WebResponsiveGridPanel1LayoutChange(Sender: TObject;
      ALayout: TResponsiveLayoutItem);
    procedure WebButton2Click(Sender: TObject);
    procedure WebBrowserControl1Click(Sender: TObject);
    procedure WebBrowserControl1DblClick(Sender: TObject);
    procedure WebButton3Click(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
    procedure WebButton5Click(Sender: TObject);
    procedure WebButton6Click(Sender: TObject);
    procedure WebButton7Click(Sender: TObject);
    procedure WebButton8Click(Sender: TObject);
    procedure WebButton9Click(Sender: TObject);
    procedure WebButton10Click(Sender: TObject);
    procedure WebButton11Click(Sender: TObject);
    procedure WebButton12Click(Sender: TObject);
    procedure WebButton13Click(Sender: TObject);
    procedure WebButton14Click(Sender: TObject);
    procedure WebButton15Click(Sender: TObject);
    procedure WebButton16Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form7: TForm7;

implementation

{$R *.dfm}

procedure TForm7.WebBrowserControl1Click(Sender: TObject);
begin
         showmessage('clicked');
         Application.Navigate('testimonials/1AlphaRiggingWireRope.pdf',ntBlank);
end;

procedure TForm7.WebBrowserControl1DblClick(Sender: TObject);
begin
         showmessage('double clicked');
         Application.Navigate('testimonials/1AlphaRiggingWireRope.pdf');
end;

procedure TForm7.WebButton10Click(Sender: TObject);
begin
        Application.Navigate('certs/AQIS-Lanotec-Citra-Force-2018.pdf',ntBlank);
end;

procedure TForm7.WebButton11Click(Sender: TObject);
begin
        Application.Navigate('certs/AQIS-Lanotec-Citra-Force-2018.pdf',ntBlank);
end;

procedure TForm7.WebButton12Click(Sender: TObject);
begin
        Application.Navigate('certs/AQIS-Lanotec-Citra-Force-2018.pdf',ntBlank);
end;

procedure TForm7.WebButton13Click(Sender: TObject);
begin
        Application.Navigate('certs/AQIS-Lanotec-Citra-Force-2018.pdf',ntBlank);
end;

procedure TForm7.WebButton14Click(Sender: TObject);
begin
        Application.Navigate('certs/AQIS-Lanotec-Citra-Force-2018.pdf',ntBlank);
end;

procedure TForm7.WebButton15Click(Sender: TObject);
begin
        Application.Navigate('certs/AQIS-Lanotec-Citra-Force-2018.pdf',ntBlank);
end;

procedure TForm7.WebButton16Click(Sender: TObject);
begin
        Application.Navigate('certs/AQIS-Lanotec-Citra-Force-2018.pdf',ntBlank);
end;

procedure TForm7.WebButton2Click(Sender: TObject);
var
i : Integer;
begin
        Application.Navigate('certs/AQIS-Lanotec-Type-A-Grease-2018.pdf',ntBlank);
//  WebTableRates.RowCount:=15000+1;
//  for i:= 0 to 15000 do
//    begin
//      WebTableRates.Cells[0,i]:=i.ToString;
//      WebTableRates.Cells[1,i]:=i.ToString;
//      WebTableRates.Cells[2,i]:=i.ToString;
//      WebTableRates.Cells[3,i]:=i.ToString;
//      WebTableRates.Cells[4,i]:=i.ToString;
//      WebTableRates.Cells[5,i]:=i.ToString;
//      WebTableRates.Cells[6,i]:=i.ToString;
//      WebTableRates.Cells[7,i]:=i.ToString;
//      WebTableRates.Cells[8,i]:=i.ToString;
//      WebTableRates.Cells[9,i]:=i.ToString;
//      WebTableRates.Cells[10,i]:=i.ToString;
//end;
end;

procedure TForm7.WebButton3Click(Sender: TObject);
begin
         Application.Navigate('certs/AQIS-Lanotec-Citra-Force-2018.pdf',ntBlank);
end;

procedure TForm7.WebButton4Click(Sender: TObject);
begin
        Application.Navigate('certs/AQIS-Lanotec-Citra-Force-2018.pdf',ntBlank);
end;

procedure TForm7.WebButton5Click(Sender: TObject);
begin
        Application.Navigate('certs/AQIS-Lanotec-Citra-Force-2018.pdf',ntBlank);
end;

procedure TForm7.WebButton6Click(Sender: TObject);
begin
        Application.Navigate('certs/AQIS-Lanotec-Citra-Force-2018.pdf',ntBlank);
end;

procedure TForm7.WebButton7Click(Sender: TObject);
begin
        Application.Navigate('certs/AQIS-Lanotec-Citra-Force-2018.pdf',ntBlank);
end;

procedure TForm7.WebButton8Click(Sender: TObject);
begin
        Application.Navigate('certs/AQIS-Lanotec-Citra-Force-2018.pdf',ntBlank);
end;

procedure TForm7.WebButton9Click(Sender: TObject);
begin
        Application.Navigate('certs/AQIS-Lanotec-Citra-Force-2018.pdf',ntBlank);
end;

procedure TForm7.WebFormCreate(Sender: TObject);
var
el1,el2,el3,el4,el5,el6,el7,el8,el9,el10,el11,el12: TJSHTMLElement;
begin
       //Application.InsertCSS('themecss','bluevoltage/theme.css');
      // WebComboBoxrequestType.ElementHandle.setAttribute('required', '');
//      WebTableRates.Cells[0,0] := '2 at2 2 at2 2 at2 2 at2 2 at2 2 at2 2 at2';
//      WebTableRates.Cells[0,1] := 'sales@toolfolks.com';
//      WebTableRates.Cells[0,2] := 'http://www.toolfolks.com';
//      WebTableRates.Cells[2,2] := '2 at2 2 at2 2 at2 2 at2 2 at2 2 at2 2 at2';
       webMemoExperience.ElementHandle.setAttribute('required', '');
       Application.InsertCSS('themecss','fresca/myTheme.css');
       el1 := WebMultimediaPlayer1.ElementHandle;
       el2 := WebMultimediaPlayer2.ElementHandle;
       el3 := WebMultimediaPlayer3.ElementHandle;
       el4 := WebMultimediaPlayer4.ElementHandle;
       el5 := WebMultimediaPlayer5.ElementHandle;
       el6 := WebMultimediaPlayer6.ElementHandle;
       el7 := WebMultimediaPlayer7.ElementHandle;
       el8 := WebMultimediaPlayer8.ElementHandle;
//       el9 := WebMultimediaPlayer9.ElementHandle;
//       el10 := WebMultimediaPlayer10.ElementHandle;
//       el11 := WebMultimediaPlayer11.ElementHandle;
//       el12 := WebMultimediaPlayer12.ElementHandle;
      asm
      el1.poster = 'videoThumbnails/retail.png';
      el2.poster = 'videoThumbnails/industrial.png';
      el3.poster = 'videoThumbnails/forklift.png';
      el4.poster = 'videoThumbnails/military.png';
      el5.poster = 'videoThumbnails/oilAndGas.png';
      el6.poster = 'videoThumbnails/cranes.png';
      el7.poster = 'videoThumbnails/marine.png';
      el8.poster = 'videoThumbnails/rustPrevention.png';
      //RespGrid1.firstElementChild.setProperty('text-align','center');

//      el9.poster = 'videoThumbnails/intro1.png';
//      el10.poster = 'videoThumbnails/eurika.png';
//      el11.poster = 'videoThumbnails/intro1.png';
//      el12.poster = 'videoThumbnails/eurika.png';
      end;

end;

procedure TForm7.WebHTMLForm2Submit(Sender: TObject);
var
requestType,firstName,surname,street,town,state,zip,mobile,email,experience, urlData, encodedMessage : string;
begin
//
//    WebHttpRequestSendJobEnquiry.URL :=  'http://92.27.68.65:7777/getRockVideos';
//    WebHttpRequestSendJobEnquiry.Execute();
     // requestType := WebComboBoxrequestType.Text;
      firstName := webEditFirstName.Text;
      surname := webEditSurname.Text;
     // street := webEditStreet.Text;
    //  town := webEditTown.Text;
    //  state := webEditState.Text;
   //   zip := webEditZip.Text;
      mobile := webEditMobile.Text;
      email  := webEditEmail.Text;
      experience := webMemoExperience.Text;
      encodedMessage := encodeURIComponent(experience);
      window.open('mailto:info@pickandship.co.uk?subject=Fulfilment Enquiry&body=%0D%0A'+firstName + '%0D%0A' + surname + '%0D%0A' +  mobile + '%0D%0A' + email + '%0D%0A' + encodedMessage  );

end;

procedure TForm7.WebResponsiveGridPanel1LayoutChange(Sender: TObject;
  ALayout: TResponsiveLayoutItem);
begin
      case ALayout.Index of
  0:
    begin
      WebImageControl1.URL := 'videoThumbnails/logo20.png';

    end;
  1:
    begin
      WebImageControl1.URL := 'videoThumbnails/logo20.png';

    end;
  2:
    begin
      WebImageControl1.URL := 'videoThumbnails/logo15.png';

    end;
  end;
end;

procedure TForm7.LoadDFMValues;
begin
  inherited LoadDFMValues;

  webControlMain := TPageControl.Create(Self);
  WebPageControlIntroduction := TTabSheet.Create(Self);
  WebScrollBox1 := TScrollBox.Create(Self);
  WebResponsiveGridPanel2 := TResponsiveGridPanel.Create(Self);
  WebMultimediaPlayer1 := TMultimediaPlayer.Create('card shadow p-3 mb-5 bg-white rounded');
  WebMultimediaPlayer2 := TMultimediaPlayer.Create(Self);
  WebMultimediaPlayer3 := TMultimediaPlayer.Create(Self);
  WebMultimediaPlayer4 := TMultimediaPlayer.Create(Self);
  WebMultimediaPlayer5 := TMultimediaPlayer.Create(Self);
  WebMultimediaPlayer6 := TMultimediaPlayer.Create(Self);
  WebMultimediaPlayer7 := TMultimediaPlayer.Create(Self);
  WebMultimediaPlayer8 := TMultimediaPlayer.Create(Self);
  webPageControlTestimonials := TTabSheet.Create(Self);
  WebScrollBox4 := TScrollBox.Create(Self);
  WebResponsiveGridPanel3 := TResponsiveGridPanel.Create(Self);
  WebBrowserControl1 := TBrowserControl.Create(Self);
  WebBrowserControl2 := TBrowserControl.Create(Self);
  WebBrowserControl3 := TBrowserControl.Create(Self);
  WebBrowserControl4 := TBrowserControl.Create(Self);
  WebBrowserControl5 := TBrowserControl.Create(Self);
  webPageControlCertification := TTabSheet.Create(Self);
  WebScrollBox5 := TScrollBox.Create(Self);
  WebResponsiveGridPanelOuterCerts := TResponsiveGridPanel.Create(Self);
  WebResponsiveGridPanel6 := TResponsiveGridPanel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebBrowserControl6 := TBrowserControl.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebResponsiveGridPanel7 := TResponsiveGridPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebBrowserControl7 := TBrowserControl.Create(Self);
  WebButton3 := TButton.Create(Self);
  WebResponsiveGridPanel8 := TResponsiveGridPanel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebBrowserControl8 := TBrowserControl.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebResponsiveGridPanel9 := TResponsiveGridPanel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebBrowserControl9 := TBrowserControl.Create(Self);
  WebButton5 := TButton.Create(Self);
  WebResponsiveGridPanel10 := TResponsiveGridPanel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebBrowserControl10 := TBrowserControl.Create(Self);
  WebButton6 := TButton.Create(Self);
  WebResponsiveGridPanel11 := TResponsiveGridPanel.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  WebBrowserControl11 := TBrowserControl.Create(Self);
  WebButton7 := TButton.Create(Self);
  WebResponsiveGridPanel12 := TResponsiveGridPanel.Create(Self);
  WebLabel11 := TLabel.Create(Self);
  WebBrowserControl12 := TBrowserControl.Create(Self);
  WebButton8 := TButton.Create(Self);
  WebResponsiveGridPanel13 := TResponsiveGridPanel.Create(Self);
  WebLabel12 := TLabel.Create(Self);
  WebBrowserControl13 := TBrowserControl.Create(Self);
  WebButton9 := TButton.Create(Self);
  WebResponsiveGridPanel14 := TResponsiveGridPanel.Create(Self);
  WebLabel13 := TLabel.Create(Self);
  WebBrowserControl14 := TBrowserControl.Create(Self);
  WebButton10 := TButton.Create(Self);
  WebResponsiveGridPanel15 := TResponsiveGridPanel.Create(Self);
  WebLabel14 := TLabel.Create(Self);
  WebBrowserControl15 := TBrowserControl.Create(Self);
  WebButton11 := TButton.Create(Self);
  WebResponsiveGridPanel16 := TResponsiveGridPanel.Create(Self);
  WebLabel15 := TLabel.Create(Self);
  WebBrowserControl16 := TBrowserControl.Create(Self);
  WebButton12 := TButton.Create(Self);
  WebResponsiveGridPanel17 := TResponsiveGridPanel.Create(Self);
  WebLabel16 := TLabel.Create(Self);
  WebBrowserControl17 := TBrowserControl.Create(Self);
  WebButton13 := TButton.Create(Self);
  WebResponsiveGridPanel18 := TResponsiveGridPanel.Create(Self);
  WebLabel17 := TLabel.Create(Self);
  WebBrowserControl18 := TBrowserControl.Create(Self);
  WebButton14 := TButton.Create(Self);
  WebResponsiveGridPanel19 := TResponsiveGridPanel.Create(Self);
  WebLabel18 := TLabel.Create(Self);
  WebBrowserControl19 := TBrowserControl.Create(Self);
  WebButton15 := TButton.Create(Self);
  WebResponsiveGridPanel20 := TResponsiveGridPanel.Create(Self);
  WebLabel19 := TLabel.Create(Self);
  WebBrowserControl20 := TBrowserControl.Create(Self);
  WebButton16 := TButton.Create(Self);
  WebPageControlEnquiry := TTabSheet.Create(Self);
  WebHTMLForm2 := THTMLForm.Create(Self);
  WebScrollBox3 := TScrollBox.Create(Self);
  WebResponsiveGridPanel5 := TResponsiveGridPanel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  webEditFirstName := TEdit.Create(Self);
  webEditSurname := TEdit.Create(Self);
  webEditMobile := TEdit.Create(Self);
  webEditEmail := TEdit.Create(Self);
  webMemoExperience := TMemo.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebPanel3 := TPanel.Create(Self);
  WebPageControlContacts := TTabSheet.Create(Self);
  WebScrollBox2 := TScrollBox.Create(Self);
  WebResponsiveGridPanel4 := TResponsiveGridPanel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebBitBtn3 := TBitBtn.Create(Self);
  WebBitBtn4 := TBitBtn.Create(Self);
  WebBitBtn1 := TBitBtn.Create(Self);
  WebBitBtn2 := TBitBtn.Create(Self);
  WebBitBtn5 := TBitBtn.Create(Self);
  WebBitBtn6 := TBitBtn.Create(Self);
  WebBitBtn7 := TBitBtn.Create(Self);
  WebResponsiveGridPanel1 := TResponsiveGridPanel.Create('RespGrid1');
  WebImageControl1 := TImageControl.Create(Self);

  webControlMain.BeforeLoadDFMValues;
  WebPageControlIntroduction.BeforeLoadDFMValues;
  WebScrollBox1.BeforeLoadDFMValues;
  WebResponsiveGridPanel2.BeforeLoadDFMValues;
  WebMultimediaPlayer1.BeforeLoadDFMValues;
  WebMultimediaPlayer2.BeforeLoadDFMValues;
  WebMultimediaPlayer3.BeforeLoadDFMValues;
  WebMultimediaPlayer4.BeforeLoadDFMValues;
  WebMultimediaPlayer5.BeforeLoadDFMValues;
  WebMultimediaPlayer6.BeforeLoadDFMValues;
  WebMultimediaPlayer7.BeforeLoadDFMValues;
  WebMultimediaPlayer8.BeforeLoadDFMValues;
  webPageControlTestimonials.BeforeLoadDFMValues;
  WebScrollBox4.BeforeLoadDFMValues;
  WebResponsiveGridPanel3.BeforeLoadDFMValues;
  WebBrowserControl1.BeforeLoadDFMValues;
  WebBrowserControl2.BeforeLoadDFMValues;
  WebBrowserControl3.BeforeLoadDFMValues;
  WebBrowserControl4.BeforeLoadDFMValues;
  WebBrowserControl5.BeforeLoadDFMValues;
  webPageControlCertification.BeforeLoadDFMValues;
  WebScrollBox5.BeforeLoadDFMValues;
  WebResponsiveGridPanelOuterCerts.BeforeLoadDFMValues;
  WebResponsiveGridPanel6.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebBrowserControl6.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebResponsiveGridPanel7.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebBrowserControl7.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebResponsiveGridPanel8.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebBrowserControl8.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebResponsiveGridPanel9.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebBrowserControl9.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebResponsiveGridPanel10.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebBrowserControl10.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  WebResponsiveGridPanel11.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  WebBrowserControl11.BeforeLoadDFMValues;
  WebButton7.BeforeLoadDFMValues;
  WebResponsiveGridPanel12.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  WebBrowserControl12.BeforeLoadDFMValues;
  WebButton8.BeforeLoadDFMValues;
  WebResponsiveGridPanel13.BeforeLoadDFMValues;
  WebLabel12.BeforeLoadDFMValues;
  WebBrowserControl13.BeforeLoadDFMValues;
  WebButton9.BeforeLoadDFMValues;
  WebResponsiveGridPanel14.BeforeLoadDFMValues;
  WebLabel13.BeforeLoadDFMValues;
  WebBrowserControl14.BeforeLoadDFMValues;
  WebButton10.BeforeLoadDFMValues;
  WebResponsiveGridPanel15.BeforeLoadDFMValues;
  WebLabel14.BeforeLoadDFMValues;
  WebBrowserControl15.BeforeLoadDFMValues;
  WebButton11.BeforeLoadDFMValues;
  WebResponsiveGridPanel16.BeforeLoadDFMValues;
  WebLabel15.BeforeLoadDFMValues;
  WebBrowserControl16.BeforeLoadDFMValues;
  WebButton12.BeforeLoadDFMValues;
  WebResponsiveGridPanel17.BeforeLoadDFMValues;
  WebLabel16.BeforeLoadDFMValues;
  WebBrowserControl17.BeforeLoadDFMValues;
  WebButton13.BeforeLoadDFMValues;
  WebResponsiveGridPanel18.BeforeLoadDFMValues;
  WebLabel17.BeforeLoadDFMValues;
  WebBrowserControl18.BeforeLoadDFMValues;
  WebButton14.BeforeLoadDFMValues;
  WebResponsiveGridPanel19.BeforeLoadDFMValues;
  WebLabel18.BeforeLoadDFMValues;
  WebBrowserControl19.BeforeLoadDFMValues;
  WebButton15.BeforeLoadDFMValues;
  WebResponsiveGridPanel20.BeforeLoadDFMValues;
  WebLabel19.BeforeLoadDFMValues;
  WebBrowserControl20.BeforeLoadDFMValues;
  WebButton16.BeforeLoadDFMValues;
  WebPageControlEnquiry.BeforeLoadDFMValues;
  WebHTMLForm2.BeforeLoadDFMValues;
  WebScrollBox3.BeforeLoadDFMValues;
  WebResponsiveGridPanel5.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  webEditFirstName.BeforeLoadDFMValues;
  webEditSurname.BeforeLoadDFMValues;
  webEditMobile.BeforeLoadDFMValues;
  webEditEmail.BeforeLoadDFMValues;
  webMemoExperience.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebPanel3.BeforeLoadDFMValues;
  WebPageControlContacts.BeforeLoadDFMValues;
  WebScrollBox2.BeforeLoadDFMValues;
  WebResponsiveGridPanel4.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebBitBtn3.BeforeLoadDFMValues;
  WebBitBtn4.BeforeLoadDFMValues;
  WebBitBtn1.BeforeLoadDFMValues;
  WebBitBtn2.BeforeLoadDFMValues;
  WebBitBtn5.BeforeLoadDFMValues;
  WebBitBtn6.BeforeLoadDFMValues;
  WebBitBtn7.BeforeLoadDFMValues;
  WebResponsiveGridPanel1.BeforeLoadDFMValues;
  WebImageControl1.BeforeLoadDFMValues;
  try
    Name := 'Form7';
    Width := 640;
    Height := 5000;
    Color := clNone;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    webControlMain.SetParentComponent(Self);
    webControlMain.Name := 'webControlMain';
    webControlMain.Left := 0;
    webControlMain.Top := 65;
    webControlMain.Width := 640;
    webControlMain.Height := 4935;
    webControlMain.Margins.Left := 0;
    webControlMain.Margins.Top := 20;
    webControlMain.Margins.Right := 0;
    webControlMain.Margins.Bottom := 20;
    webControlMain.ElementClassName := 'pagination grid-aligned tablist';
    webControlMain.HeightStyle := ssPercent;
    webControlMain.WidthStyle := ssPercent;
    webControlMain.Align := alClient;
    webControlMain.TabIndex := 0;
    webControlMain.TabOrder := 0;
    WebPageControlIntroduction.SetParentComponent(webControlMain);
    WebPageControlIntroduction.Name := 'WebPageControlIntroduction';
    WebPageControlIntroduction.Left := 0;
    WebPageControlIntroduction.Top := 20;
    WebPageControlIntroduction.Width := 640;
    WebPageControlIntroduction.Height := 4915;
    WebPageControlIntroduction.ElementClassName := 'noBorder1 backGrad2';
    WebPageControlIntroduction.Caption := 'Introduction';
    WebPageControlIntroduction.ChildOrder := 1;
    WebScrollBox1.SetParentComponent(WebPageControlIntroduction);
    WebScrollBox1.Name := 'WebScrollBox1';
    WebScrollBox1.Left := 0;
    WebScrollBox1.Top := 0;
    WebScrollBox1.Width := 640;
    WebScrollBox1.Height := 1734;
    WebScrollBox1.ElementClassName := 'noBorder1';
    WebScrollBox1.HeightStyle := ssPercent;
    WebScrollBox1.Align := alTop;
    WebScrollBox1.BorderStyle := bsSingle;
    WebScrollBox1.ChildOrder := 1;
    WebScrollBox1.Color := clWindow;
    WebResponsiveGridPanel2.SetParentComponent(WebScrollBox1);
    WebResponsiveGridPanel2.Name := 'WebResponsiveGridPanel2';
    WebResponsiveGridPanel2.AlignWithMargins := True;
    WebResponsiveGridPanel2.Left := 10;
    WebResponsiveGridPanel2.Top := 20;
    WebResponsiveGridPanel2.Width := 620;
    WebResponsiveGridPanel2.Height := 1711;
    WebResponsiveGridPanel2.Margins.Left := 10;
    WebResponsiveGridPanel2.Margins.Top := 20;
    WebResponsiveGridPanel2.Margins.Right := 10;
    WebResponsiveGridPanel2.ElementClassName := 'grid-aligned ';
    WebResponsiveGridPanel2.HeightStyle := ssAuto;
    WebResponsiveGridPanel2.WidthStyle := ssPercent;
    WebResponsiveGridPanel2.WidthPercent := 98.000000000000000000;
    WebResponsiveGridPanel2.Align := alClient;
    WebResponsiveGridPanel2.ChildOrder := 1;
    WebResponsiveGridPanel2.ControlCollection.Clear;
    with WebResponsiveGridPanel2.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebMultimediaPlayer1;
    end;
    with WebResponsiveGridPanel2.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebMultimediaPlayer2;
    end;
    with WebResponsiveGridPanel2.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebMultimediaPlayer3;
    end;
    with WebResponsiveGridPanel2.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebMultimediaPlayer4;
    end;
    with WebResponsiveGridPanel2.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebMultimediaPlayer5;
    end;
    with WebResponsiveGridPanel2.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebMultimediaPlayer6;
    end;
    with WebResponsiveGridPanel2.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebMultimediaPlayer7;
    end;
    with WebResponsiveGridPanel2.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebMultimediaPlayer8;
    end;
    WebResponsiveGridPanel2.Color := clNone;
    WebResponsiveGridPanel2.ElementFont := efCSS;
    WebResponsiveGridPanel2.Layout.Clear;
    with WebResponsiveGridPanel2.Layout.Add do
    begin
      ColumnGap := '10';
      Description := 'Smartphone';
      RowGap := '10';
      Style := '1fr';
      Width := 575;
    end;
    with WebResponsiveGridPanel2.Layout.Add do
    begin
      ColumnGap := '10';
      Description := 'Tablet';
      RowGap := '10';
      Style := '1fr 1fr';
      Width := 768;
    end;
    with WebResponsiveGridPanel2.Layout.Add do
    begin
      ColumnGap := '10';
      Description := 'Desktop';
      RowGap := '10';
      Style := '1fr 1fr 1fr';
      Width := 991;
    end;
    with WebResponsiveGridPanel2.Layout.Add do
    begin
      ColumnGap := '10';
      Description := 'Large Desktop';
      RowGap := '10';
      Style := '1fr 1fr 1fr 1fr';
      Width := 1199;
    end;
    WebMultimediaPlayer1.SetParentComponent(WebResponsiveGridPanel2);
    WebMultimediaPlayer1.Name := 'WebMultimediaPlayer1';
    WebMultimediaPlayer1.AlignWithMargins := True;
    WebMultimediaPlayer1.Left := 10;
    WebMultimediaPlayer1.Top := 10;
    WebMultimediaPlayer1.Width := 270;
    WebMultimediaPlayer1.Height := 225;
    WebMultimediaPlayer1.Margins.Left := 1;
    WebMultimediaPlayer1.Margins.Top := 1;
    WebMultimediaPlayer1.Margins.Right := 1;
    WebMultimediaPlayer1.Margins.Bottom := 1;
    WebMultimediaPlayer1.ElementClassName := 'img2 backGrad1';
    WebMultimediaPlayer1.HeightStyle := ssPercent;
    WebMultimediaPlayer1.WidthStyle := ssPercent;
    WebMultimediaPlayer1.HeightPercent := 90.000000000000000000;
    WebMultimediaPlayer1.WidthPercent := 90.000000000000000000;
    WebMultimediaPlayer1.Anchors := [];
    WebMultimediaPlayer1.Controls := True;
    WebMultimediaPlayer1.PlaybackRate := 1.000000000000000000;
    WebMultimediaPlayer1.URL := 'videos/Retail-iphonepod.mp4';
    WebMultimediaPlayer2.SetParentComponent(WebResponsiveGridPanel2);
    WebMultimediaPlayer2.Name := 'WebMultimediaPlayer2';
    WebMultimediaPlayer2.AlignWithMargins := True;
    WebMultimediaPlayer2.Left := 320;
    WebMultimediaPlayer2.Top := 10;
    WebMultimediaPlayer2.Width := 270;
    WebMultimediaPlayer2.Height := 225;
    WebMultimediaPlayer2.Margins.Left := 1;
    WebMultimediaPlayer2.Margins.Top := 1;
    WebMultimediaPlayer2.Margins.Right := 1;
    WebMultimediaPlayer2.Margins.Bottom := 1;
    WebMultimediaPlayer2.ElementClassName := 'img2 backGrad1';
    WebMultimediaPlayer2.HeightStyle := ssPercent;
    WebMultimediaPlayer2.WidthStyle := ssPercent;
    WebMultimediaPlayer2.HeightPercent := 90.000000000000000000;
    WebMultimediaPlayer2.WidthPercent := 90.000000000000000000;
    WebMultimediaPlayer2.Anchors := [];
    WebMultimediaPlayer2.Controls := True;
    WebMultimediaPlayer2.PlaybackRate := 1.000000000000000000;
    WebMultimediaPlayer2.URL := 'videos/Industrial-appletv4k.mp4';
    WebMultimediaPlayer3.SetParentComponent(WebResponsiveGridPanel2);
    WebMultimediaPlayer3.Name := 'WebMultimediaPlayer3';
    WebMultimediaPlayer3.AlignWithMargins := True;
    WebMultimediaPlayer3.Left := 10;
    WebMultimediaPlayer3.Top := 245;
    WebMultimediaPlayer3.Width := 270;
    WebMultimediaPlayer3.Height := 225;
    WebMultimediaPlayer3.Margins.Left := 1;
    WebMultimediaPlayer3.Margins.Top := 1;
    WebMultimediaPlayer3.Margins.Right := 1;
    WebMultimediaPlayer3.Margins.Bottom := 1;
    WebMultimediaPlayer3.ElementClassName := 'img2 backGrad1';
    WebMultimediaPlayer3.HeightStyle := ssPercent;
    WebMultimediaPlayer3.WidthStyle := ssPercent;
    WebMultimediaPlayer3.HeightPercent := 90.000000000000000000;
    WebMultimediaPlayer3.WidthPercent := 90.000000000000000000;
    WebMultimediaPlayer3.Anchors := [];
    WebMultimediaPlayer3.Controls := True;
    WebMultimediaPlayer3.PlaybackRate := 1.000000000000000000;
    WebMultimediaPlayer3.URL := 'videos/AAA_Shortened_Forklift_Revised[1].mp4';
    WebMultimediaPlayer4.SetParentComponent(WebResponsiveGridPanel2);
    WebMultimediaPlayer4.Name := 'WebMultimediaPlayer4';
    WebMultimediaPlayer4.AlignWithMargins := True;
    WebMultimediaPlayer4.Left := 320;
    WebMultimediaPlayer4.Top := 245;
    WebMultimediaPlayer4.Width := 270;
    WebMultimediaPlayer4.Height := 225;
    WebMultimediaPlayer4.Margins.Left := 1;
    WebMultimediaPlayer4.Margins.Top := 1;
    WebMultimediaPlayer4.Margins.Right := 1;
    WebMultimediaPlayer4.Margins.Bottom := 1;
    WebMultimediaPlayer4.ElementClassName := 'img2 backGrad1';
    WebMultimediaPlayer4.HeightStyle := ssPercent;
    WebMultimediaPlayer4.WidthStyle := ssPercent;
    WebMultimediaPlayer4.HeightPercent := 90.000000000000000000;
    WebMultimediaPlayer4.WidthPercent := 90.000000000000000000;
    WebMultimediaPlayer4.Anchors := [];
    WebMultimediaPlayer4.Controls := True;
    WebMultimediaPlayer4.PlaybackRate := 1.000000000000000000;
    WebMultimediaPlayer4.URL := 'videos/Military Preventative maintenance Applications by Lanogreen.mp4';
    WebMultimediaPlayer5.SetParentComponent(WebResponsiveGridPanel2);
    WebMultimediaPlayer5.Name := 'WebMultimediaPlayer5';
    WebMultimediaPlayer5.AlignWithMargins := True;
    WebMultimediaPlayer5.Left := 10;
    WebMultimediaPlayer5.Top := 480;
    WebMultimediaPlayer5.Width := 270;
    WebMultimediaPlayer5.Height := 225;
    WebMultimediaPlayer5.Margins.Left := 1;
    WebMultimediaPlayer5.Margins.Top := 1;
    WebMultimediaPlayer5.Margins.Right := 1;
    WebMultimediaPlayer5.Margins.Bottom := 1;
    WebMultimediaPlayer5.ElementClassName := 'img2 backGrad1';
    WebMultimediaPlayer5.HeightStyle := ssPercent;
    WebMultimediaPlayer5.WidthStyle := ssPercent;
    WebMultimediaPlayer5.HeightPercent := 90.000000000000000000;
    WebMultimediaPlayer5.WidthPercent := 90.000000000000000000;
    WebMultimediaPlayer5.Anchors := [];
    WebMultimediaPlayer5.Controls := True;
    WebMultimediaPlayer5.PlaybackRate := 1.000000000000000000;
    WebMultimediaPlayer5.URL := 'videos/Oil _ Gas Rig Preventative Maintenance Applications.mp4';
    WebMultimediaPlayer6.SetParentComponent(WebResponsiveGridPanel2);
    WebMultimediaPlayer6.Name := 'WebMultimediaPlayer6';
    WebMultimediaPlayer6.AlignWithMargins := True;
    WebMultimediaPlayer6.Left := 320;
    WebMultimediaPlayer6.Top := 480;
    WebMultimediaPlayer6.Width := 270;
    WebMultimediaPlayer6.Height := 225;
    WebMultimediaPlayer6.Margins.Left := 1;
    WebMultimediaPlayer6.Margins.Top := 1;
    WebMultimediaPlayer6.Margins.Right := 1;
    WebMultimediaPlayer6.Margins.Bottom := 1;
    WebMultimediaPlayer6.ElementClassName := 'img2 backGrad1';
    WebMultimediaPlayer6.HeightStyle := ssPercent;
    WebMultimediaPlayer6.WidthStyle := ssPercent;
    WebMultimediaPlayer6.HeightPercent := 90.000000000000000000;
    WebMultimediaPlayer6.WidthPercent := 90.000000000000000000;
    WebMultimediaPlayer6.Anchors := [];
    WebMultimediaPlayer6.Controls := True;
    WebMultimediaPlayer6.PlaybackRate := 1.000000000000000000;
    WebMultimediaPlayer6.URL := 'videos/Operational _ Preventative Maintenance - Crane Application.mp4';
    WebMultimediaPlayer7.SetParentComponent(WebResponsiveGridPanel2);
    WebMultimediaPlayer7.Name := 'WebMultimediaPlayer7';
    WebMultimediaPlayer7.AlignWithMargins := True;
    WebMultimediaPlayer7.Left := 10;
    WebMultimediaPlayer7.Top := 715;
    WebMultimediaPlayer7.Width := 270;
    WebMultimediaPlayer7.Height := 225;
    WebMultimediaPlayer7.Margins.Left := 1;
    WebMultimediaPlayer7.Margins.Top := 1;
    WebMultimediaPlayer7.Margins.Right := 1;
    WebMultimediaPlayer7.Margins.Bottom := 1;
    WebMultimediaPlayer7.ElementClassName := 'img2 backGrad1';
    WebMultimediaPlayer7.HeightStyle := ssPercent;
    WebMultimediaPlayer7.WidthStyle := ssPercent;
    WebMultimediaPlayer7.HeightPercent := 90.000000000000000000;
    WebMultimediaPlayer7.WidthPercent := 90.000000000000000000;
    WebMultimediaPlayer7.Anchors := [];
    WebMultimediaPlayer7.Controls := True;
    WebMultimediaPlayer7.PlaybackRate := 1.000000000000000000;
    WebMultimediaPlayer7.URL := 'videos/Operational _ Preventative Marine Applications.mp4';
    WebMultimediaPlayer8.SetParentComponent(WebResponsiveGridPanel2);
    WebMultimediaPlayer8.Name := 'WebMultimediaPlayer8';
    WebMultimediaPlayer8.AlignWithMargins := True;
    WebMultimediaPlayer8.Left := 320;
    WebMultimediaPlayer8.Top := 715;
    WebMultimediaPlayer8.Width := 270;
    WebMultimediaPlayer8.Height := 225;
    WebMultimediaPlayer8.Margins.Left := 1;
    WebMultimediaPlayer8.Margins.Top := 1;
    WebMultimediaPlayer8.Margins.Right := 1;
    WebMultimediaPlayer8.Margins.Bottom := 1;
    WebMultimediaPlayer8.ElementClassName := 'img2 backGrad1';
    WebMultimediaPlayer8.HeightStyle := ssPercent;
    WebMultimediaPlayer8.WidthStyle := ssPercent;
    WebMultimediaPlayer8.HeightPercent := 90.000000000000000000;
    WebMultimediaPlayer8.WidthPercent := 90.000000000000000000;
    WebMultimediaPlayer8.Anchors := [];
    WebMultimediaPlayer8.Controls := True;
    WebMultimediaPlayer8.PlaybackRate := 1.000000000000000000;
    WebMultimediaPlayer8.URL := 'videos/Isolation _ Rust Prevention test by Lanogreen.mp4';
    webPageControlTestimonials.SetParentComponent(webControlMain);
    webPageControlTestimonials.Name := 'webPageControlTestimonials';
    webPageControlTestimonials.Left := 0;
    webPageControlTestimonials.Top := 20;
    webPageControlTestimonials.Width := 640;
    webPageControlTestimonials.Height := 4915;
    webPageControlTestimonials.Caption := 'Testimonials';
    webPageControlTestimonials.ChildOrder := 4;
    webPageControlTestimonials.ElementFont := efCSS;
    WebScrollBox4.SetParentComponent(webPageControlTestimonials);
    WebScrollBox4.Name := 'WebScrollBox4';
    WebScrollBox4.Left := 0;
    WebScrollBox4.Top := 0;
    WebScrollBox4.Width := 640;
    WebScrollBox4.Height := 1734;
    WebScrollBox4.ElementClassName := 'noBorder1';
    WebScrollBox4.HeightStyle := ssPercent;
    WebScrollBox4.Align := alTop;
    WebScrollBox4.BorderStyle := bsSingle;
    WebScrollBox4.ChildOrder := 1;
    WebScrollBox4.Color := clWindow;
    WebResponsiveGridPanel3.SetParentComponent(WebScrollBox4);
    WebResponsiveGridPanel3.Name := 'WebResponsiveGridPanel3';
    WebResponsiveGridPanel3.AlignWithMargins := True;
    WebResponsiveGridPanel3.Left := 10;
    WebResponsiveGridPanel3.Top := 20;
    WebResponsiveGridPanel3.Width := 620;
    WebResponsiveGridPanel3.Height := 1711;
    WebResponsiveGridPanel3.Margins.Left := 10;
    WebResponsiveGridPanel3.Margins.Top := 20;
    WebResponsiveGridPanel3.Margins.Right := 10;
    WebResponsiveGridPanel3.ElementClassName := 'grid-aligned ';
    WebResponsiveGridPanel3.HeightStyle := ssAuto;
    WebResponsiveGridPanel3.WidthStyle := ssPercent;
    WebResponsiveGridPanel3.WidthPercent := 98.000000000000000000;
    WebResponsiveGridPanel3.Align := alClient;
    WebResponsiveGridPanel3.ChildOrder := 1;
    WebResponsiveGridPanel3.ControlCollection.Clear;
    with WebResponsiveGridPanel3.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBrowserControl1;
    end;
    with WebResponsiveGridPanel3.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBrowserControl2;
    end;
    with WebResponsiveGridPanel3.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBrowserControl3;
    end;
    with WebResponsiveGridPanel3.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBrowserControl4;
    end;
    with WebResponsiveGridPanel3.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBrowserControl5;
    end;
    WebResponsiveGridPanel3.Color := clNone;
    WebResponsiveGridPanel3.ElementFont := efCSS;
    WebResponsiveGridPanel3.Layout.Clear;
    with WebResponsiveGridPanel3.Layout.Add do
    begin
      ColumnGap := '10';
      Description := 'Smartphone';
      RowGap := '10';
      Style := '1fr';
      Width := 575;
    end;
    with WebResponsiveGridPanel3.Layout.Add do
    begin
      ColumnGap := '10';
      Description := 'Tablet';
      RowGap := '10';
      Style := '1fr 1fr';
      Width := 768;
    end;
    with WebResponsiveGridPanel3.Layout.Add do
    begin
      ColumnGap := '10';
      Description := 'Desktop';
      RowGap := '10';
      Style := '1fr 1fr 1fr';
      Width := 991;
    end;
    with WebResponsiveGridPanel3.Layout.Add do
    begin
      ColumnGap := '10';
      Description := 'Large Desktop';
      RowGap := '10';
      Style := '1fr 1fr 1fr 1fr';
      Width := 1199;
    end;
    WebBrowserControl1.SetParentComponent(WebResponsiveGridPanel3);
    WebBrowserControl1.Name := 'WebBrowserControl1';
    WebBrowserControl1.AlignWithMargins := True;
    WebBrowserControl1.Left := 10;
    WebBrowserControl1.Top := 10;
    WebBrowserControl1.Width := 270;
    WebBrowserControl1.Height := 375;
    WebBrowserControl1.ElementClassName := 'img2 backGrad1';
    WebBrowserControl1.WidthStyle := ssPercent;
    WebBrowserControl1.HeightPercent := 90.000000000000000000;
    WebBrowserControl1.WidthPercent := 90.000000000000000000;
    WebBrowserControl1.Sandbox := [];
    WebBrowserControl1.URL := 'testimonials/1AlphaRiggingWireRope.pdf';
    SetEvent(WebBrowserControl1, Self, 'OnClick', 'WebBrowserControl1Click');
    SetEvent(WebBrowserControl1, Self, 'OnDblClick', 'WebBrowserControl1DblClick');
    WebBrowserControl2.SetParentComponent(WebResponsiveGridPanel3);
    WebBrowserControl2.Name := 'WebBrowserControl2';
    WebBrowserControl2.AlignWithMargins := True;
    WebBrowserControl2.Left := 320;
    WebBrowserControl2.Top := 10;
    WebBrowserControl2.Width := 270;
    WebBrowserControl2.Height := 375;
    WebBrowserControl2.ElementClassName := 'img2 backGrad1';
    WebBrowserControl2.WidthStyle := ssPercent;
    WebBrowserControl2.HeightPercent := 90.000000000000000000;
    WebBrowserControl2.WidthPercent := 90.000000000000000000;
    WebBrowserControl2.Anchors := [];
    WebBrowserControl2.ChildOrder := 1;
    WebBrowserControl2.Sandbox := [];
    WebBrowserControl2.URL := 'testimonials/2 Ingersoll - Corrosive enviro.pdf';
    SetEvent(WebBrowserControl2, Self, 'OnClick', 'WebBrowserControl1Click');
    SetEvent(WebBrowserControl2, Self, 'OnDblClick', 'WebBrowserControl1DblClick');
    WebBrowserControl3.SetParentComponent(WebResponsiveGridPanel3);
    WebBrowserControl3.Name := 'WebBrowserControl3';
    WebBrowserControl3.AlignWithMargins := True;
    WebBrowserControl3.Left := 10;
    WebBrowserControl3.Top := 395;
    WebBrowserControl3.Width := 270;
    WebBrowserControl3.Height := 375;
    WebBrowserControl3.ElementClassName := 'img2 backGrad1';
    WebBrowserControl3.WidthStyle := ssPercent;
    WebBrowserControl3.HeightPercent := 90.000000000000000000;
    WebBrowserControl3.WidthPercent := 90.000000000000000000;
    WebBrowserControl3.Anchors := [];
    WebBrowserControl3.ChildOrder := 2;
    WebBrowserControl3.Sandbox := [];
    WebBrowserControl3.URL := 'testimonials/5 Bullivants - Wire Rope.pdf';
    SetEvent(WebBrowserControl3, Self, 'OnClick', 'WebBrowserControl1Click');
    SetEvent(WebBrowserControl3, Self, 'OnDblClick', 'WebBrowserControl1DblClick');
    WebBrowserControl4.SetParentComponent(WebResponsiveGridPanel3);
    WebBrowserControl4.Name := 'WebBrowserControl4';
    WebBrowserControl4.AlignWithMargins := True;
    WebBrowserControl4.Left := 320;
    WebBrowserControl4.Top := 395;
    WebBrowserControl4.Width := 270;
    WebBrowserControl4.Height := 375;
    WebBrowserControl4.ElementClassName := 'img2 backGrad1';
    WebBrowserControl4.WidthStyle := ssPercent;
    WebBrowserControl4.HeightPercent := 90.000000000000000000;
    WebBrowserControl4.WidthPercent := 90.000000000000000000;
    WebBrowserControl4.Anchors := [];
    WebBrowserControl4.ChildOrder := 3;
    WebBrowserControl4.Sandbox := [];
    WebBrowserControl4.URL := 'testimonials/6 Shell Bitumen(Darwin) - Protect and Clean.pdf';
    SetEvent(WebBrowserControl4, Self, 'OnClick', 'WebBrowserControl1Click');
    SetEvent(WebBrowserControl4, Self, 'OnDblClick', 'WebBrowserControl1DblClick');
    WebBrowserControl5.SetParentComponent(WebResponsiveGridPanel3);
    WebBrowserControl5.Name := 'WebBrowserControl5';
    WebBrowserControl5.AlignWithMargins := True;
    WebBrowserControl5.Left := 10;
    WebBrowserControl5.Top := 780;
    WebBrowserControl5.Width := 270;
    WebBrowserControl5.Height := 375;
    WebBrowserControl5.ElementClassName := 'img2 backGrad1';
    WebBrowserControl5.WidthStyle := ssPercent;
    WebBrowserControl5.HeightPercent := 90.000000000000000000;
    WebBrowserControl5.WidthPercent := 90.000000000000000000;
    WebBrowserControl5.Anchors := [];
    WebBrowserControl5.ChildOrder := 4;
    WebBrowserControl5.Sandbox := [];
    WebBrowserControl5.URL := 'testimonials/1AlphaRiggingWireRope.pdf';
    SetEvent(WebBrowserControl5, Self, 'OnClick', 'WebBrowserControl1Click');
    SetEvent(WebBrowserControl5, Self, 'OnDblClick', 'WebBrowserControl1DblClick');
    webPageControlCertification.SetParentComponent(webControlMain);
    webPageControlCertification.Name := 'webPageControlCertification';
    webPageControlCertification.Left := 0;
    webPageControlCertification.Top := 20;
    webPageControlCertification.Width := 640;
    webPageControlCertification.Height := 4915;
    webPageControlCertification.Caption := 'Certification';
    webPageControlCertification.ChildOrder := 10;
    webPageControlCertification.ElementFont := efCSS;
    WebScrollBox5.SetParentComponent(webPageControlCertification);
    WebScrollBox5.Name := 'WebScrollBox5';
    WebScrollBox5.Left := 0;
    WebScrollBox5.Top := 0;
    WebScrollBox5.Width := 640;
    WebScrollBox5.Height := 5000;
    WebScrollBox5.ElementClassName := 'noBorder1';
    WebScrollBox5.HeightStyle := ssPercent;
    WebScrollBox5.Align := alTop;
    WebScrollBox5.BorderStyle := bsSingle;
    WebScrollBox5.ChildOrder := 1;
    WebScrollBox5.Color := clWindow;
    WebResponsiveGridPanelOuterCerts.SetParentComponent(WebScrollBox5);
    WebResponsiveGridPanelOuterCerts.Name := 'WebResponsiveGridPanelOuterCerts';
    WebResponsiveGridPanelOuterCerts.AlignWithMargins := True;
    WebResponsiveGridPanelOuterCerts.Left := 10;
    WebResponsiveGridPanelOuterCerts.Top := 20;
    WebResponsiveGridPanelOuterCerts.Width := 620;
    WebResponsiveGridPanelOuterCerts.Height := 4977;
    WebResponsiveGridPanelOuterCerts.Margins.Left := 10;
    WebResponsiveGridPanelOuterCerts.Margins.Top := 20;
    WebResponsiveGridPanelOuterCerts.Margins.Right := 10;
    WebResponsiveGridPanelOuterCerts.ElementClassName := 'grid-aligned ';
    WebResponsiveGridPanelOuterCerts.HeightStyle := ssAuto;
    WebResponsiveGridPanelOuterCerts.WidthStyle := ssPercent;
    WebResponsiveGridPanelOuterCerts.WidthPercent := 98.000000000000000000;
    WebResponsiveGridPanelOuterCerts.Align := alClient;
    WebResponsiveGridPanelOuterCerts.ChildOrder := 1;
    WebResponsiveGridPanelOuterCerts.ControlCollection.Clear;
    with WebResponsiveGridPanelOuterCerts.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebResponsiveGridPanel6;
    end;
    with WebResponsiveGridPanelOuterCerts.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebResponsiveGridPanel7;
    end;
    with WebResponsiveGridPanelOuterCerts.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebResponsiveGridPanel8;
    end;
    with WebResponsiveGridPanelOuterCerts.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebResponsiveGridPanel9;
    end;
    with WebResponsiveGridPanelOuterCerts.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebResponsiveGridPanel10;
    end;
    with WebResponsiveGridPanelOuterCerts.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebResponsiveGridPanel11;
    end;
    with WebResponsiveGridPanelOuterCerts.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebResponsiveGridPanel12;
    end;
    with WebResponsiveGridPanelOuterCerts.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebResponsiveGridPanel13;
    end;
    with WebResponsiveGridPanelOuterCerts.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebResponsiveGridPanel14;
    end;
    with WebResponsiveGridPanelOuterCerts.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebResponsiveGridPanel15;
    end;
    with WebResponsiveGridPanelOuterCerts.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebResponsiveGridPanel16;
    end;
    with WebResponsiveGridPanelOuterCerts.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebResponsiveGridPanel17;
    end;
    with WebResponsiveGridPanelOuterCerts.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebResponsiveGridPanel18;
    end;
    with WebResponsiveGridPanelOuterCerts.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebResponsiveGridPanel19;
    end;
    with WebResponsiveGridPanelOuterCerts.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebResponsiveGridPanel20;
    end;
    WebResponsiveGridPanelOuterCerts.Color := clNone;
    WebResponsiveGridPanelOuterCerts.ElementFont := efCSS;
    WebResponsiveGridPanelOuterCerts.Layout.Clear;
    with WebResponsiveGridPanelOuterCerts.Layout.Add do
    begin
      ColumnGap := '10';
      Description := 'Smartphone';
      RowGap := '10';
      Style := '1fr';
      Width := 575;
    end;
    with WebResponsiveGridPanelOuterCerts.Layout.Add do
    begin
      ColumnGap := '10';
      Description := 'Tablet';
      RowGap := '10';
      Style := '1fr 1fr';
      Width := 768;
    end;
    with WebResponsiveGridPanelOuterCerts.Layout.Add do
    begin
      ColumnGap := '10';
      Description := 'Desktop';
      RowGap := '10';
      Style := '1fr 1fr 1fr';
      Width := 991;
    end;
    with WebResponsiveGridPanelOuterCerts.Layout.Add do
    begin
      ColumnGap := '10';
      Description := 'Large Desktop';
      RowGap := '10';
      Style := '1fr 1fr 1fr 1fr';
      Width := 1199;
    end;
    WebResponsiveGridPanel6.SetParentComponent(WebResponsiveGridPanelOuterCerts);
    WebResponsiveGridPanel6.Name := 'WebResponsiveGridPanel6';
    WebResponsiveGridPanel6.Left := 10;
    WebResponsiveGridPanel6.Top := 10;
    WebResponsiveGridPanel6.Width := 280;
    WebResponsiveGridPanel6.Height := 519;
    WebResponsiveGridPanel6.Anchors := [];
    WebResponsiveGridPanel6.ChildOrder := 1;
    WebResponsiveGridPanel6.ControlCollection.Clear;
    with WebResponsiveGridPanel6.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBrowserControl6;
    end;
    with WebResponsiveGridPanel6.ControlCollection.Add do
    begin
      Column := 0;
      Row := 2;
      Control := WebLabel6;
    end;
    with WebResponsiveGridPanel6.ControlCollection.Add do
    begin
      Column := 0;
      Row := 1;
      Control := WebButton2;
    end;
    WebResponsiveGridPanel6.Color := clWhite;
    WebResponsiveGridPanel6.ElementFont := efCSS;
    WebResponsiveGridPanel6.Layout.Clear;
    with WebResponsiveGridPanel6.Layout.Add do
    begin
      Description := 'Smartphone';
      Style := '1fr';
      Width := 575;
    end;
    with WebResponsiveGridPanel6.Layout.Add do
    begin
      Description := 'Tablet';
      Style := '1fr 1fr';
      Width := 768;
    end;
    with WebResponsiveGridPanel6.Layout.Add do
    begin
      Description := 'Desktop';
      Style := '1fr 1fr 1fr';
      Width := 991;
    end;
    with WebResponsiveGridPanel6.Layout.Add do
    begin
      Description := 'Large Desktop';
      Style := '1fr 1fr 1fr 1fr';
      Width := 1199;
    end;
    WebLabel6.SetParentComponent(WebResponsiveGridPanel6);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 0;
    WebLabel6.Top := 375;
    WebLabel6.Width := 280;
    WebLabel6.Height := 21;
    WebLabel6.Align := alTop;
    WebLabel6.Alignment := taCenter;
    WebLabel6.AutoSize := False;
    WebLabel6.Caption := 'AQIS-Lanotec-Citra-Force-Aerosol-Declaration-Hazardous-Substance-Meat-Export';
    WebLabel6.Font.Charset := DEFAULT_CHARSET;
    WebLabel6.Font.Color := clWindowText;
    WebLabel6.Font.Height := -16;
    WebLabel6.Font.Name := 'Segoe UI';
    WebLabel6.Font.Style := [];
    WebLabel6.HeightPercent := 10.000000000000000000;
    WebLabel6.ParentFont := False;
    WebLabel6.WordWrap := True;
    WebLabel6.WidthStyle := ssPercent;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebBrowserControl6.SetParentComponent(WebResponsiveGridPanel6);
    WebBrowserControl6.Name := 'WebBrowserControl6';
    WebBrowserControl6.Left := 0;
    WebBrowserControl6.Top := 0;
    WebBrowserControl6.Width := 280;
    WebBrowserControl6.Height := 375;
    WebBrowserControl6.ElementClassName := 'img2 backGrad1';
    WebBrowserControl6.WidthStyle := ssPercent;
    WebBrowserControl6.HeightPercent := 80.000000000000000000;
    WebBrowserControl6.Align := alTop;
    WebBrowserControl6.ChildOrder := 1;
    WebBrowserControl6.Sandbox := [];
    WebBrowserControl6.URL := 'certs/AQIS-Lanotec-Citra-Force-Aerosol-Declaration-Hazardous-Substance-Meat-Export.pdf';
    WebButton2.SetParentComponent(WebResponsiveGridPanel6);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 0;
    WebButton2.Top := 396;
    WebButton2.Width := 140;
    WebButton2.Height := 25;
    WebButton2.Align := alBottom;
    WebButton2.Caption := 'Open';
    WebButton2.ChildOrder := 2;
    WebButton2.ElementClassName := 'btn btn-light';
    WebButton2.ElementFont := efCSS;
    WebButton2.HeightPercent := 10.000000000000000000;
    WebButton2.WidthPercent := 50.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebResponsiveGridPanel7.SetParentComponent(WebResponsiveGridPanelOuterCerts);
    WebResponsiveGridPanel7.Name := 'WebResponsiveGridPanel7';
    WebResponsiveGridPanel7.Left := 320;
    WebResponsiveGridPanel7.Top := 10;
    WebResponsiveGridPanel7.Width := 280;
    WebResponsiveGridPanel7.Height := 519;
    WebResponsiveGridPanel7.Anchors := [];
    WebResponsiveGridPanel7.ChildOrder := 1;
    WebResponsiveGridPanel7.ControlCollection.Clear;
    with WebResponsiveGridPanel7.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBrowserControl7;
    end;
    with WebResponsiveGridPanel7.ControlCollection.Add do
    begin
      Column := 0;
      Row := 2;
      Control := WebLabel1;
    end;
    with WebResponsiveGridPanel7.ControlCollection.Add do
    begin
      Column := 0;
      Row := 1;
      Control := WebButton3;
    end;
    WebResponsiveGridPanel7.Color := clWhite;
    WebResponsiveGridPanel7.ElementFont := efCSS;
    WebResponsiveGridPanel7.Layout.Clear;
    with WebResponsiveGridPanel7.Layout.Add do
    begin
      Description := 'Smartphone';
      Style := '1fr';
      Width := 575;
    end;
    with WebResponsiveGridPanel7.Layout.Add do
    begin
      Description := 'Tablet';
      Style := '1fr 1fr';
      Width := 768;
    end;
    with WebResponsiveGridPanel7.Layout.Add do
    begin
      Description := 'Desktop';
      Style := '1fr 1fr 1fr';
      Width := 991;
    end;
    with WebResponsiveGridPanel7.Layout.Add do
    begin
      Description := 'Large Desktop';
      Style := '1fr 1fr 1fr 1fr';
      Width := 1199;
    end;
    WebLabel1.SetParentComponent(WebResponsiveGridPanel7);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 0;
    WebLabel1.Top := 375;
    WebLabel1.Width := 280;
    WebLabel1.Height := 21;
    WebLabel1.Align := alTop;
    WebLabel1.Alignment := taCenter;
    WebLabel1.AutoSize := False;
    WebLabel1.Caption := 'AQIS-Lanotec-Citra-Force-2018';
    WebLabel1.ElementFont := efCSS;
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 10.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WordWrap := True;
    WebLabel1.WidthStyle := ssPercent;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebBrowserControl7.SetParentComponent(WebResponsiveGridPanel7);
    WebBrowserControl7.Name := 'WebBrowserControl7';
    WebBrowserControl7.Left := 0;
    WebBrowserControl7.Top := 0;
    WebBrowserControl7.Width := 280;
    WebBrowserControl7.Height := 375;
    WebBrowserControl7.ElementClassName := 'img2 backGrad1';
    WebBrowserControl7.WidthStyle := ssPercent;
    WebBrowserControl7.HeightPercent := 80.000000000000000000;
    WebBrowserControl7.Align := alTop;
    WebBrowserControl7.ChildOrder := 1;
    WebBrowserControl7.Sandbox := [];
    WebBrowserControl7.URL := 'certs/AQIS-Lanotec-Citra-Force-2018.pdf';
    WebButton3.SetParentComponent(WebResponsiveGridPanel7);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 0;
    WebButton3.Top := 396;
    WebButton3.Width := 140;
    WebButton3.Height := 25;
    WebButton3.Align := alBottom;
    WebButton3.Caption := 'Open';
    WebButton3.ChildOrder := 2;
    WebButton3.ElementClassName := 'btn btn-light';
    WebButton3.ElementFont := efCSS;
    WebButton3.HeightPercent := 10.000000000000000000;
    WebButton3.WidthPercent := 50.000000000000000000;
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton2Click');
    WebResponsiveGridPanel8.SetParentComponent(WebResponsiveGridPanelOuterCerts);
    WebResponsiveGridPanel8.Name := 'WebResponsiveGridPanel8';
    WebResponsiveGridPanel8.Left := 10;
    WebResponsiveGridPanel8.Top := 539;
    WebResponsiveGridPanel8.Width := 280;
    WebResponsiveGridPanel8.Height := 519;
    WebResponsiveGridPanel8.Anchors := [];
    WebResponsiveGridPanel8.ChildOrder := 1;
    WebResponsiveGridPanel8.ControlCollection.Clear;
    with WebResponsiveGridPanel8.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBrowserControl8;
    end;
    with WebResponsiveGridPanel8.ControlCollection.Add do
    begin
      Column := 0;
      Row := 2;
      Control := WebLabel7;
    end;
    with WebResponsiveGridPanel8.ControlCollection.Add do
    begin
      Column := 0;
      Row := 1;
      Control := WebButton4;
    end;
    WebResponsiveGridPanel8.Color := clWhite;
    WebResponsiveGridPanel8.ElementFont := efCSS;
    WebResponsiveGridPanel8.Layout.Clear;
    with WebResponsiveGridPanel8.Layout.Add do
    begin
      Description := 'Smartphone';
      Style := '1fr';
      Width := 575;
    end;
    with WebResponsiveGridPanel8.Layout.Add do
    begin
      Description := 'Tablet';
      Style := '1fr 1fr';
      Width := 768;
    end;
    with WebResponsiveGridPanel8.Layout.Add do
    begin
      Description := 'Desktop';
      Style := '1fr 1fr 1fr';
      Width := 991;
    end;
    with WebResponsiveGridPanel8.Layout.Add do
    begin
      Description := 'Large Desktop';
      Style := '1fr 1fr 1fr 1fr';
      Width := 1199;
    end;
    WebLabel7.SetParentComponent(WebResponsiveGridPanel8);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 0;
    WebLabel7.Top := 375;
    WebLabel7.Width := 280;
    WebLabel7.Height := 21;
    WebLabel7.Align := alTop;
    WebLabel7.Alignment := taCenter;
    WebLabel7.AutoSize := False;
    WebLabel7.Caption := 'AQIS-Lanotec-Heavy-Duty-2018';
    WebLabel7.ElementFont := efCSS;
    WebLabel7.Font.Charset := DEFAULT_CHARSET;
    WebLabel7.Font.Color := clWindowText;
    WebLabel7.Font.Height := -16;
    WebLabel7.Font.Name := 'Segoe UI';
    WebLabel7.Font.Style := [];
    WebLabel7.HeightStyle := ssAuto;
    WebLabel7.HeightPercent := 10.000000000000000000;
    WebLabel7.ParentFont := False;
    WebLabel7.WordWrap := True;
    WebLabel7.WidthStyle := ssPercent;
    WebLabel7.WidthPercent := 100.000000000000000000;
    WebBrowserControl8.SetParentComponent(WebResponsiveGridPanel8);
    WebBrowserControl8.Name := 'WebBrowserControl8';
    WebBrowserControl8.Left := 0;
    WebBrowserControl8.Top := 0;
    WebBrowserControl8.Width := 280;
    WebBrowserControl8.Height := 375;
    WebBrowserControl8.ElementClassName := 'img2 backGrad1';
    WebBrowserControl8.WidthStyle := ssPercent;
    WebBrowserControl8.HeightPercent := 80.000000000000000000;
    WebBrowserControl8.Align := alTop;
    WebBrowserControl8.ChildOrder := 1;
    WebBrowserControl8.Sandbox := [];
    WebBrowserControl8.URL := 'certs/AQIS-Lanotec-Heavy-Duty-2018.pdf';
    WebButton4.SetParentComponent(WebResponsiveGridPanel8);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 0;
    WebButton4.Top := 396;
    WebButton4.Width := 140;
    WebButton4.Height := 25;
    WebButton4.Align := alBottom;
    WebButton4.Caption := 'Open';
    WebButton4.ChildOrder := 2;
    WebButton4.ElementClassName := 'btn btn-light';
    WebButton4.ElementFont := efCSS;
    WebButton4.HeightStyle := ssAuto;
    WebButton4.HeightPercent := 10.000000000000000000;
    WebButton4.WidthPercent := 50.000000000000000000;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton2Click');
    WebResponsiveGridPanel9.SetParentComponent(WebResponsiveGridPanelOuterCerts);
    WebResponsiveGridPanel9.Name := 'WebResponsiveGridPanel9';
    WebResponsiveGridPanel9.Left := 320;
    WebResponsiveGridPanel9.Top := 539;
    WebResponsiveGridPanel9.Width := 280;
    WebResponsiveGridPanel9.Height := 519;
    WebResponsiveGridPanel9.Anchors := [];
    WebResponsiveGridPanel9.ChildOrder := 1;
    WebResponsiveGridPanel9.ControlCollection.Clear;
    with WebResponsiveGridPanel9.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBrowserControl9;
    end;
    with WebResponsiveGridPanel9.ControlCollection.Add do
    begin
      Column := 0;
      Row := 2;
      Control := WebLabel8;
    end;
    with WebResponsiveGridPanel9.ControlCollection.Add do
    begin
      Column := 0;
      Row := 1;
      Control := WebButton5;
    end;
    WebResponsiveGridPanel9.Color := clWhite;
    WebResponsiveGridPanel9.ElementFont := efCSS;
    WebResponsiveGridPanel9.Layout.Clear;
    with WebResponsiveGridPanel9.Layout.Add do
    begin
      Description := 'Smartphone';
      Style := '1fr';
      Width := 575;
    end;
    with WebResponsiveGridPanel9.Layout.Add do
    begin
      Description := 'Tablet';
      Style := '1fr 1fr';
      Width := 768;
    end;
    with WebResponsiveGridPanel9.Layout.Add do
    begin
      Description := 'Desktop';
      Style := '1fr 1fr 1fr';
      Width := 991;
    end;
    with WebResponsiveGridPanel9.Layout.Add do
    begin
      Description := 'Large Desktop';
      Style := '1fr 1fr 1fr 1fr';
      Width := 1199;
    end;
    WebLabel8.SetParentComponent(WebResponsiveGridPanel9);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 0;
    WebLabel8.Top := 375;
    WebLabel8.Width := 280;
    WebLabel8.Height := 21;
    WebLabel8.Align := alTop;
    WebLabel8.Alignment := taCenter;
    WebLabel8.AutoSize := False;
    WebLabel8.Caption := 'AQIS-Lanotec-Type-A-Grease-2018';
    WebLabel8.ElementFont := efCSS;
    WebLabel8.Font.Charset := DEFAULT_CHARSET;
    WebLabel8.Font.Color := clWindowText;
    WebLabel8.Font.Height := -16;
    WebLabel8.Font.Name := 'Segoe UI';
    WebLabel8.Font.Style := [];
    WebLabel8.HeightStyle := ssAuto;
    WebLabel8.HeightPercent := 10.000000000000000000;
    WebLabel8.ParentFont := False;
    WebLabel8.WordWrap := True;
    WebLabel8.WidthStyle := ssPercent;
    WebLabel8.WidthPercent := 100.000000000000000000;
    WebBrowserControl9.SetParentComponent(WebResponsiveGridPanel9);
    WebBrowserControl9.Name := 'WebBrowserControl9';
    WebBrowserControl9.Left := 0;
    WebBrowserControl9.Top := 0;
    WebBrowserControl9.Width := 280;
    WebBrowserControl9.Height := 375;
    WebBrowserControl9.ElementClassName := 'img2 backGrad1';
    WebBrowserControl9.WidthStyle := ssPercent;
    WebBrowserControl9.HeightPercent := 80.000000000000000000;
    WebBrowserControl9.Align := alTop;
    WebBrowserControl9.ChildOrder := 1;
    WebBrowserControl9.Sandbox := [];
    WebBrowserControl9.URL := 'certs/AQIS-Lanotec-Type-A-Grease-2018.pdf';
    WebButton5.SetParentComponent(WebResponsiveGridPanel9);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 0;
    WebButton5.Top := 396;
    WebButton5.Width := 140;
    WebButton5.Height := 25;
    WebButton5.Align := alBottom;
    WebButton5.Caption := 'Open';
    WebButton5.ChildOrder := 2;
    WebButton5.ElementClassName := 'btn btn-light';
    WebButton5.ElementFont := efCSS;
    WebButton5.HeightStyle := ssAuto;
    WebButton5.HeightPercent := 10.000000000000000000;
    WebButton5.WidthPercent := 50.000000000000000000;
    SetEvent(WebButton5, Self, 'OnClick', 'WebButton2Click');
    WebResponsiveGridPanel10.SetParentComponent(WebResponsiveGridPanelOuterCerts);
    WebResponsiveGridPanel10.Name := 'WebResponsiveGridPanel10';
    WebResponsiveGridPanel10.Left := 10;
    WebResponsiveGridPanel10.Top := 1068;
    WebResponsiveGridPanel10.Width := 280;
    WebResponsiveGridPanel10.Height := 519;
    WebResponsiveGridPanel10.Anchors := [];
    WebResponsiveGridPanel10.ChildOrder := 1;
    WebResponsiveGridPanel10.ControlCollection.Clear;
    with WebResponsiveGridPanel10.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBrowserControl10;
    end;
    with WebResponsiveGridPanel10.ControlCollection.Add do
    begin
      Column := 0;
      Row := 2;
      Control := WebLabel9;
    end;
    with WebResponsiveGridPanel10.ControlCollection.Add do
    begin
      Column := 0;
      Row := 1;
      Control := WebButton6;
    end;
    WebResponsiveGridPanel10.Color := clWhite;
    WebResponsiveGridPanel10.ElementFont := efCSS;
    WebResponsiveGridPanel10.Layout.Clear;
    with WebResponsiveGridPanel10.Layout.Add do
    begin
      Description := 'Smartphone';
      Style := '1fr';
      Width := 575;
    end;
    with WebResponsiveGridPanel10.Layout.Add do
    begin
      Description := 'Tablet';
      Style := '1fr 1fr';
      Width := 768;
    end;
    with WebResponsiveGridPanel10.Layout.Add do
    begin
      Description := 'Desktop';
      Style := '1fr 1fr 1fr';
      Width := 991;
    end;
    with WebResponsiveGridPanel10.Layout.Add do
    begin
      Description := 'Large Desktop';
      Style := '1fr 1fr 1fr 1fr';
      Width := 1199;
    end;
    WebLabel9.SetParentComponent(WebResponsiveGridPanel10);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 0;
    WebLabel9.Top := 375;
    WebLabel9.Width := 280;
    WebLabel9.Height := 21;
    WebLabel9.Align := alTop;
    WebLabel9.Alignment := taCenter;
    WebLabel9.AutoSize := False;
    WebLabel9.Caption := 'AQIS-Lanotec-Citra-Force-2018';
    WebLabel9.ElementFont := efCSS;
    WebLabel9.Font.Charset := DEFAULT_CHARSET;
    WebLabel9.Font.Color := clWindowText;
    WebLabel9.Font.Height := -16;
    WebLabel9.Font.Name := 'Segoe UI';
    WebLabel9.Font.Style := [];
    WebLabel9.HeightStyle := ssAuto;
    WebLabel9.HeightPercent := 10.000000000000000000;
    WebLabel9.ParentFont := False;
    WebLabel9.WordWrap := True;
    WebLabel9.WidthStyle := ssPercent;
    WebLabel9.WidthPercent := 100.000000000000000000;
    WebBrowserControl10.SetParentComponent(WebResponsiveGridPanel10);
    WebBrowserControl10.Name := 'WebBrowserControl10';
    WebBrowserControl10.Left := 0;
    WebBrowserControl10.Top := 0;
    WebBrowserControl10.Width := 280;
    WebBrowserControl10.Height := 375;
    WebBrowserControl10.ElementClassName := 'img2 backGrad1';
    WebBrowserControl10.WidthStyle := ssPercent;
    WebBrowserControl10.HeightPercent := 80.000000000000000000;
    WebBrowserControl10.Align := alTop;
    WebBrowserControl10.ChildOrder := 1;
    WebBrowserControl10.Sandbox := [];
    WebBrowserControl10.URL := 'certs/AQIS-Lanotec-Citra-Force-2018.pdf';
    WebButton6.SetParentComponent(WebResponsiveGridPanel10);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 0;
    WebButton6.Top := 396;
    WebButton6.Width := 140;
    WebButton6.Height := 25;
    WebButton6.Align := alBottom;
    WebButton6.Caption := 'Open';
    WebButton6.ChildOrder := 2;
    WebButton6.ElementClassName := 'btn btn-light';
    WebButton6.ElementFont := efCSS;
    WebButton6.HeightStyle := ssAuto;
    WebButton6.HeightPercent := 10.000000000000000000;
    WebButton6.WidthPercent := 50.000000000000000000;
    SetEvent(WebButton6, Self, 'OnClick', 'WebButton2Click');
    WebResponsiveGridPanel11.SetParentComponent(WebResponsiveGridPanelOuterCerts);
    WebResponsiveGridPanel11.Name := 'WebResponsiveGridPanel11';
    WebResponsiveGridPanel11.Left := 320;
    WebResponsiveGridPanel11.Top := 1068;
    WebResponsiveGridPanel11.Width := 280;
    WebResponsiveGridPanel11.Height := 519;
    WebResponsiveGridPanel11.Anchors := [];
    WebResponsiveGridPanel11.ChildOrder := 1;
    WebResponsiveGridPanel11.ControlCollection.Clear;
    with WebResponsiveGridPanel11.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBrowserControl11;
    end;
    with WebResponsiveGridPanel11.ControlCollection.Add do
    begin
      Column := 0;
      Row := 2;
      Control := WebLabel10;
    end;
    with WebResponsiveGridPanel11.ControlCollection.Add do
    begin
      Column := 0;
      Row := 1;
      Control := WebButton7;
    end;
    WebResponsiveGridPanel11.Color := clWhite;
    WebResponsiveGridPanel11.ElementFont := efCSS;
    WebResponsiveGridPanel11.Layout.Clear;
    with WebResponsiveGridPanel11.Layout.Add do
    begin
      Description := 'Smartphone';
      Style := '1fr';
      Width := 575;
    end;
    with WebResponsiveGridPanel11.Layout.Add do
    begin
      Description := 'Tablet';
      Style := '1fr 1fr';
      Width := 768;
    end;
    with WebResponsiveGridPanel11.Layout.Add do
    begin
      Description := 'Desktop';
      Style := '1fr 1fr 1fr';
      Width := 991;
    end;
    with WebResponsiveGridPanel11.Layout.Add do
    begin
      Description := 'Large Desktop';
      Style := '1fr 1fr 1fr 1fr';
      Width := 1199;
    end;
    WebLabel10.SetParentComponent(WebResponsiveGridPanel11);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.Left := 0;
    WebLabel10.Top := 375;
    WebLabel10.Width := 280;
    WebLabel10.Height := 21;
    WebLabel10.Align := alTop;
    WebLabel10.Alignment := taCenter;
    WebLabel10.AutoSize := False;
    WebLabel10.Caption := 'AQIS-Lanotec-Citra-Force-2018';
    WebLabel10.ElementFont := efCSS;
    WebLabel10.Font.Charset := DEFAULT_CHARSET;
    WebLabel10.Font.Color := clWindowText;
    WebLabel10.Font.Height := -16;
    WebLabel10.Font.Name := 'Segoe UI';
    WebLabel10.Font.Style := [];
    WebLabel10.HeightStyle := ssAuto;
    WebLabel10.HeightPercent := 10.000000000000000000;
    WebLabel10.ParentFont := False;
    WebLabel10.WordWrap := True;
    WebLabel10.WidthStyle := ssPercent;
    WebLabel10.WidthPercent := 100.000000000000000000;
    WebBrowserControl11.SetParentComponent(WebResponsiveGridPanel11);
    WebBrowserControl11.Name := 'WebBrowserControl11';
    WebBrowserControl11.Left := 0;
    WebBrowserControl11.Top := 0;
    WebBrowserControl11.Width := 280;
    WebBrowserControl11.Height := 375;
    WebBrowserControl11.ElementClassName := 'img2 backGrad1';
    WebBrowserControl11.WidthStyle := ssPercent;
    WebBrowserControl11.HeightPercent := 80.000000000000000000;
    WebBrowserControl11.Align := alTop;
    WebBrowserControl11.ChildOrder := 1;
    WebBrowserControl11.Sandbox := [];
    WebBrowserControl11.URL := 'certs/AQIS-Lanotec-Citra-Force-2018.pdf';
    WebButton7.SetParentComponent(WebResponsiveGridPanel11);
    WebButton7.Name := 'WebButton7';
    WebButton7.Left := 0;
    WebButton7.Top := 396;
    WebButton7.Width := 140;
    WebButton7.Height := 25;
    WebButton7.Align := alBottom;
    WebButton7.Caption := 'Open';
    WebButton7.ChildOrder := 2;
    WebButton7.ElementClassName := 'btn btn-light';
    WebButton7.ElementFont := efCSS;
    WebButton7.HeightStyle := ssAuto;
    WebButton7.HeightPercent := 10.000000000000000000;
    WebButton7.WidthPercent := 50.000000000000000000;
    SetEvent(WebButton7, Self, 'OnClick', 'WebButton2Click');
    WebResponsiveGridPanel12.SetParentComponent(WebResponsiveGridPanelOuterCerts);
    WebResponsiveGridPanel12.Name := 'WebResponsiveGridPanel12';
    WebResponsiveGridPanel12.Left := 10;
    WebResponsiveGridPanel12.Top := 1597;
    WebResponsiveGridPanel12.Width := 280;
    WebResponsiveGridPanel12.Height := 519;
    WebResponsiveGridPanel12.Anchors := [];
    WebResponsiveGridPanel12.ChildOrder := 1;
    WebResponsiveGridPanel12.ControlCollection.Clear;
    with WebResponsiveGridPanel12.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBrowserControl12;
    end;
    with WebResponsiveGridPanel12.ControlCollection.Add do
    begin
      Column := 0;
      Row := 2;
      Control := WebLabel11;
    end;
    with WebResponsiveGridPanel12.ControlCollection.Add do
    begin
      Column := 0;
      Row := 1;
      Control := WebButton8;
    end;
    WebResponsiveGridPanel12.Color := clWhite;
    WebResponsiveGridPanel12.ElementFont := efCSS;
    WebResponsiveGridPanel12.Layout.Clear;
    with WebResponsiveGridPanel12.Layout.Add do
    begin
      Description := 'Smartphone';
      Style := '1fr';
      Width := 575;
    end;
    with WebResponsiveGridPanel12.Layout.Add do
    begin
      Description := 'Tablet';
      Style := '1fr 1fr';
      Width := 768;
    end;
    with WebResponsiveGridPanel12.Layout.Add do
    begin
      Description := 'Desktop';
      Style := '1fr 1fr 1fr';
      Width := 991;
    end;
    with WebResponsiveGridPanel12.Layout.Add do
    begin
      Description := 'Large Desktop';
      Style := '1fr 1fr 1fr 1fr';
      Width := 1199;
    end;
    WebLabel11.SetParentComponent(WebResponsiveGridPanel12);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.Left := 0;
    WebLabel11.Top := 375;
    WebLabel11.Width := 280;
    WebLabel11.Height := 21;
    WebLabel11.Align := alTop;
    WebLabel11.Alignment := taCenter;
    WebLabel11.AutoSize := False;
    WebLabel11.Caption := 'AQIS-Lanotec-Citra-Force-2018';
    WebLabel11.ElementFont := efCSS;
    WebLabel11.Font.Charset := DEFAULT_CHARSET;
    WebLabel11.Font.Color := clWindowText;
    WebLabel11.Font.Height := -16;
    WebLabel11.Font.Name := 'Segoe UI';
    WebLabel11.Font.Style := [];
    WebLabel11.HeightStyle := ssAuto;
    WebLabel11.HeightPercent := 10.000000000000000000;
    WebLabel11.ParentFont := False;
    WebLabel11.WordWrap := True;
    WebLabel11.WidthStyle := ssPercent;
    WebLabel11.WidthPercent := 100.000000000000000000;
    WebBrowserControl12.SetParentComponent(WebResponsiveGridPanel12);
    WebBrowserControl12.Name := 'WebBrowserControl12';
    WebBrowserControl12.Left := 0;
    WebBrowserControl12.Top := 0;
    WebBrowserControl12.Width := 280;
    WebBrowserControl12.Height := 375;
    WebBrowserControl12.ElementClassName := 'img2 backGrad1';
    WebBrowserControl12.WidthStyle := ssPercent;
    WebBrowserControl12.HeightPercent := 80.000000000000000000;
    WebBrowserControl12.Align := alTop;
    WebBrowserControl12.ChildOrder := 1;
    WebBrowserControl12.Sandbox := [];
    WebBrowserControl12.URL := 'certs/AQIS-Lanotec-Citra-Force-2018.pdf';
    WebButton8.SetParentComponent(WebResponsiveGridPanel12);
    WebButton8.Name := 'WebButton8';
    WebButton8.Left := 0;
    WebButton8.Top := 396;
    WebButton8.Width := 140;
    WebButton8.Height := 25;
    WebButton8.Align := alBottom;
    WebButton8.Caption := 'Open';
    WebButton8.ChildOrder := 2;
    WebButton8.ElementClassName := 'btn btn-light';
    WebButton8.ElementFont := efCSS;
    WebButton8.HeightStyle := ssAuto;
    WebButton8.HeightPercent := 10.000000000000000000;
    WebButton8.WidthPercent := 50.000000000000000000;
    SetEvent(WebButton8, Self, 'OnClick', 'WebButton2Click');
    WebResponsiveGridPanel13.SetParentComponent(WebResponsiveGridPanelOuterCerts);
    WebResponsiveGridPanel13.Name := 'WebResponsiveGridPanel13';
    WebResponsiveGridPanel13.Left := 320;
    WebResponsiveGridPanel13.Top := 1597;
    WebResponsiveGridPanel13.Width := 280;
    WebResponsiveGridPanel13.Height := 519;
    WebResponsiveGridPanel13.Anchors := [];
    WebResponsiveGridPanel13.ChildOrder := 1;
    WebResponsiveGridPanel13.ControlCollection.Clear;
    with WebResponsiveGridPanel13.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBrowserControl13;
    end;
    with WebResponsiveGridPanel13.ControlCollection.Add do
    begin
      Column := 0;
      Row := 2;
      Control := WebLabel12;
    end;
    with WebResponsiveGridPanel13.ControlCollection.Add do
    begin
      Column := 0;
      Row := 1;
      Control := WebButton9;
    end;
    WebResponsiveGridPanel13.Color := clWhite;
    WebResponsiveGridPanel13.ElementFont := efCSS;
    WebResponsiveGridPanel13.Layout.Clear;
    with WebResponsiveGridPanel13.Layout.Add do
    begin
      Description := 'Smartphone';
      Style := '1fr';
      Width := 575;
    end;
    with WebResponsiveGridPanel13.Layout.Add do
    begin
      Description := 'Tablet';
      Style := '1fr 1fr';
      Width := 768;
    end;
    with WebResponsiveGridPanel13.Layout.Add do
    begin
      Description := 'Desktop';
      Style := '1fr 1fr 1fr';
      Width := 991;
    end;
    with WebResponsiveGridPanel13.Layout.Add do
    begin
      Description := 'Large Desktop';
      Style := '1fr 1fr 1fr 1fr';
      Width := 1199;
    end;
    WebLabel12.SetParentComponent(WebResponsiveGridPanel13);
    WebLabel12.Name := 'WebLabel12';
    WebLabel12.Left := 0;
    WebLabel12.Top := 375;
    WebLabel12.Width := 280;
    WebLabel12.Height := 21;
    WebLabel12.Align := alTop;
    WebLabel12.Alignment := taCenter;
    WebLabel12.AutoSize := False;
    WebLabel12.Caption := 'AQIS-Lanotec-Citra-Force-2018';
    WebLabel12.ElementFont := efCSS;
    WebLabel12.Font.Charset := DEFAULT_CHARSET;
    WebLabel12.Font.Color := clWindowText;
    WebLabel12.Font.Height := -16;
    WebLabel12.Font.Name := 'Segoe UI';
    WebLabel12.Font.Style := [];
    WebLabel12.HeightStyle := ssAuto;
    WebLabel12.HeightPercent := 10.000000000000000000;
    WebLabel12.ParentFont := False;
    WebLabel12.WordWrap := True;
    WebLabel12.WidthStyle := ssPercent;
    WebLabel12.WidthPercent := 100.000000000000000000;
    WebBrowserControl13.SetParentComponent(WebResponsiveGridPanel13);
    WebBrowserControl13.Name := 'WebBrowserControl13';
    WebBrowserControl13.Left := 0;
    WebBrowserControl13.Top := 0;
    WebBrowserControl13.Width := 280;
    WebBrowserControl13.Height := 375;
    WebBrowserControl13.ElementClassName := 'img2 backGrad1';
    WebBrowserControl13.WidthStyle := ssPercent;
    WebBrowserControl13.HeightPercent := 80.000000000000000000;
    WebBrowserControl13.Align := alTop;
    WebBrowserControl13.ChildOrder := 1;
    WebBrowserControl13.Sandbox := [];
    WebBrowserControl13.URL := 'certs/AQIS-Lanotec-Citra-Force-2018.pdf';
    WebButton9.SetParentComponent(WebResponsiveGridPanel13);
    WebButton9.Name := 'WebButton9';
    WebButton9.Left := 0;
    WebButton9.Top := 396;
    WebButton9.Width := 140;
    WebButton9.Height := 25;
    WebButton9.Align := alBottom;
    WebButton9.Caption := 'Open';
    WebButton9.ChildOrder := 2;
    WebButton9.ElementClassName := 'btn btn-light';
    WebButton9.ElementFont := efCSS;
    WebButton9.HeightStyle := ssAuto;
    WebButton9.HeightPercent := 10.000000000000000000;
    WebButton9.WidthPercent := 50.000000000000000000;
    SetEvent(WebButton9, Self, 'OnClick', 'WebButton2Click');
    WebResponsiveGridPanel14.SetParentComponent(WebResponsiveGridPanelOuterCerts);
    WebResponsiveGridPanel14.Name := 'WebResponsiveGridPanel14';
    WebResponsiveGridPanel14.Left := 10;
    WebResponsiveGridPanel14.Top := 2126;
    WebResponsiveGridPanel14.Width := 280;
    WebResponsiveGridPanel14.Height := 519;
    WebResponsiveGridPanel14.Anchors := [];
    WebResponsiveGridPanel14.ChildOrder := 1;
    WebResponsiveGridPanel14.ControlCollection.Clear;
    with WebResponsiveGridPanel14.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBrowserControl14;
    end;
    with WebResponsiveGridPanel14.ControlCollection.Add do
    begin
      Column := 0;
      Row := 2;
      Control := WebLabel13;
    end;
    with WebResponsiveGridPanel14.ControlCollection.Add do
    begin
      Column := 0;
      Row := 1;
      Control := WebButton10;
    end;
    WebResponsiveGridPanel14.Color := clWhite;
    WebResponsiveGridPanel14.ElementFont := efCSS;
    WebResponsiveGridPanel14.Layout.Clear;
    with WebResponsiveGridPanel14.Layout.Add do
    begin
      Description := 'Smartphone';
      Style := '1fr';
      Width := 575;
    end;
    with WebResponsiveGridPanel14.Layout.Add do
    begin
      Description := 'Tablet';
      Style := '1fr 1fr';
      Width := 768;
    end;
    with WebResponsiveGridPanel14.Layout.Add do
    begin
      Description := 'Desktop';
      Style := '1fr 1fr 1fr';
      Width := 991;
    end;
    with WebResponsiveGridPanel14.Layout.Add do
    begin
      Description := 'Large Desktop';
      Style := '1fr 1fr 1fr 1fr';
      Width := 1199;
    end;
    WebLabel13.SetParentComponent(WebResponsiveGridPanel14);
    WebLabel13.Name := 'WebLabel13';
    WebLabel13.Left := 0;
    WebLabel13.Top := 375;
    WebLabel13.Width := 280;
    WebLabel13.Height := 21;
    WebLabel13.Align := alTop;
    WebLabel13.Alignment := taCenter;
    WebLabel13.AutoSize := False;
    WebLabel13.Caption := 'AQIS-Lanotec-Citra-Force-2018';
    WebLabel13.ElementFont := efCSS;
    WebLabel13.Font.Charset := DEFAULT_CHARSET;
    WebLabel13.Font.Color := clWindowText;
    WebLabel13.Font.Height := -16;
    WebLabel13.Font.Name := 'Segoe UI';
    WebLabel13.Font.Style := [];
    WebLabel13.HeightStyle := ssAuto;
    WebLabel13.HeightPercent := 10.000000000000000000;
    WebLabel13.ParentFont := False;
    WebLabel13.WordWrap := True;
    WebLabel13.WidthStyle := ssPercent;
    WebLabel13.WidthPercent := 100.000000000000000000;
    WebBrowserControl14.SetParentComponent(WebResponsiveGridPanel14);
    WebBrowserControl14.Name := 'WebBrowserControl14';
    WebBrowserControl14.Left := 0;
    WebBrowserControl14.Top := 0;
    WebBrowserControl14.Width := 280;
    WebBrowserControl14.Height := 375;
    WebBrowserControl14.ElementClassName := 'img2 backGrad1';
    WebBrowserControl14.WidthStyle := ssPercent;
    WebBrowserControl14.HeightPercent := 80.000000000000000000;
    WebBrowserControl14.Align := alTop;
    WebBrowserControl14.ChildOrder := 1;
    WebBrowserControl14.Sandbox := [];
    WebBrowserControl14.URL := 'certs/AQIS-Lanotec-Citra-Force-2018.pdf';
    WebButton10.SetParentComponent(WebResponsiveGridPanel14);
    WebButton10.Name := 'WebButton10';
    WebButton10.Left := 0;
    WebButton10.Top := 396;
    WebButton10.Width := 140;
    WebButton10.Height := 25;
    WebButton10.Align := alBottom;
    WebButton10.Caption := 'Open';
    WebButton10.ChildOrder := 2;
    WebButton10.ElementClassName := 'btn btn-light';
    WebButton10.ElementFont := efCSS;
    WebButton10.HeightStyle := ssAuto;
    WebButton10.HeightPercent := 10.000000000000000000;
    WebButton10.WidthPercent := 50.000000000000000000;
    SetEvent(WebButton10, Self, 'OnClick', 'WebButton2Click');
    WebResponsiveGridPanel15.SetParentComponent(WebResponsiveGridPanelOuterCerts);
    WebResponsiveGridPanel15.Name := 'WebResponsiveGridPanel15';
    WebResponsiveGridPanel15.Left := 320;
    WebResponsiveGridPanel15.Top := 2126;
    WebResponsiveGridPanel15.Width := 280;
    WebResponsiveGridPanel15.Height := 519;
    WebResponsiveGridPanel15.Anchors := [];
    WebResponsiveGridPanel15.ChildOrder := 1;
    WebResponsiveGridPanel15.ControlCollection.Clear;
    with WebResponsiveGridPanel15.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBrowserControl15;
    end;
    with WebResponsiveGridPanel15.ControlCollection.Add do
    begin
      Column := 0;
      Row := 2;
      Control := WebLabel14;
    end;
    with WebResponsiveGridPanel15.ControlCollection.Add do
    begin
      Column := 0;
      Row := 1;
      Control := WebButton11;
    end;
    WebResponsiveGridPanel15.Color := clWhite;
    WebResponsiveGridPanel15.ElementFont := efCSS;
    WebResponsiveGridPanel15.Layout.Clear;
    with WebResponsiveGridPanel15.Layout.Add do
    begin
      Description := 'Smartphone';
      Style := '1fr';
      Width := 575;
    end;
    with WebResponsiveGridPanel15.Layout.Add do
    begin
      Description := 'Tablet';
      Style := '1fr 1fr';
      Width := 768;
    end;
    with WebResponsiveGridPanel15.Layout.Add do
    begin
      Description := 'Desktop';
      Style := '1fr 1fr 1fr';
      Width := 991;
    end;
    with WebResponsiveGridPanel15.Layout.Add do
    begin
      Description := 'Large Desktop';
      Style := '1fr 1fr 1fr 1fr';
      Width := 1199;
    end;
    WebLabel14.SetParentComponent(WebResponsiveGridPanel15);
    WebLabel14.Name := 'WebLabel14';
    WebLabel14.Left := 0;
    WebLabel14.Top := 375;
    WebLabel14.Width := 280;
    WebLabel14.Height := 21;
    WebLabel14.Align := alTop;
    WebLabel14.Alignment := taCenter;
    WebLabel14.AutoSize := False;
    WebLabel14.Caption := 'AQIS-Lanotec-Citra-Force-2018';
    WebLabel14.ElementFont := efCSS;
    WebLabel14.Font.Charset := DEFAULT_CHARSET;
    WebLabel14.Font.Color := clWindowText;
    WebLabel14.Font.Height := -16;
    WebLabel14.Font.Name := 'Segoe UI';
    WebLabel14.Font.Style := [];
    WebLabel14.HeightStyle := ssAuto;
    WebLabel14.HeightPercent := 10.000000000000000000;
    WebLabel14.ParentFont := False;
    WebLabel14.WordWrap := True;
    WebLabel14.WidthStyle := ssPercent;
    WebLabel14.WidthPercent := 100.000000000000000000;
    WebBrowserControl15.SetParentComponent(WebResponsiveGridPanel15);
    WebBrowserControl15.Name := 'WebBrowserControl15';
    WebBrowserControl15.Left := 0;
    WebBrowserControl15.Top := 0;
    WebBrowserControl15.Width := 280;
    WebBrowserControl15.Height := 375;
    WebBrowserControl15.ElementClassName := 'img2 backGrad1';
    WebBrowserControl15.WidthStyle := ssPercent;
    WebBrowserControl15.HeightPercent := 80.000000000000000000;
    WebBrowserControl15.Align := alTop;
    WebBrowserControl15.ChildOrder := 1;
    WebBrowserControl15.Sandbox := [];
    WebBrowserControl15.URL := 'certs/AQIS-Lanotec-Citra-Force-2018.pdf';
    WebButton11.SetParentComponent(WebResponsiveGridPanel15);
    WebButton11.Name := 'WebButton11';
    WebButton11.Left := 0;
    WebButton11.Top := 396;
    WebButton11.Width := 140;
    WebButton11.Height := 25;
    WebButton11.Align := alBottom;
    WebButton11.Caption := 'Open';
    WebButton11.ChildOrder := 2;
    WebButton11.ElementClassName := 'btn btn-light';
    WebButton11.ElementFont := efCSS;
    WebButton11.HeightStyle := ssAuto;
    WebButton11.HeightPercent := 10.000000000000000000;
    WebButton11.WidthPercent := 50.000000000000000000;
    SetEvent(WebButton11, Self, 'OnClick', 'WebButton2Click');
    WebResponsiveGridPanel16.SetParentComponent(WebResponsiveGridPanelOuterCerts);
    WebResponsiveGridPanel16.Name := 'WebResponsiveGridPanel16';
    WebResponsiveGridPanel16.Left := 10;
    WebResponsiveGridPanel16.Top := 2655;
    WebResponsiveGridPanel16.Width := 280;
    WebResponsiveGridPanel16.Height := 519;
    WebResponsiveGridPanel16.Anchors := [];
    WebResponsiveGridPanel16.ChildOrder := 1;
    WebResponsiveGridPanel16.ControlCollection.Clear;
    with WebResponsiveGridPanel16.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBrowserControl16;
    end;
    with WebResponsiveGridPanel16.ControlCollection.Add do
    begin
      Column := 0;
      Row := 2;
      Control := WebLabel15;
    end;
    with WebResponsiveGridPanel16.ControlCollection.Add do
    begin
      Column := 0;
      Row := 1;
      Control := WebButton12;
    end;
    WebResponsiveGridPanel16.Color := clWhite;
    WebResponsiveGridPanel16.ElementFont := efCSS;
    WebResponsiveGridPanel16.Layout.Clear;
    with WebResponsiveGridPanel16.Layout.Add do
    begin
      Description := 'Smartphone';
      Style := '1fr';
      Width := 575;
    end;
    with WebResponsiveGridPanel16.Layout.Add do
    begin
      Description := 'Tablet';
      Style := '1fr 1fr';
      Width := 768;
    end;
    with WebResponsiveGridPanel16.Layout.Add do
    begin
      Description := 'Desktop';
      Style := '1fr 1fr 1fr';
      Width := 991;
    end;
    with WebResponsiveGridPanel16.Layout.Add do
    begin
      Description := 'Large Desktop';
      Style := '1fr 1fr 1fr 1fr';
      Width := 1199;
    end;
    WebLabel15.SetParentComponent(WebResponsiveGridPanel16);
    WebLabel15.Name := 'WebLabel15';
    WebLabel15.Left := 0;
    WebLabel15.Top := 375;
    WebLabel15.Width := 280;
    WebLabel15.Height := 21;
    WebLabel15.Align := alTop;
    WebLabel15.Alignment := taCenter;
    WebLabel15.AutoSize := False;
    WebLabel15.Caption := 'AQIS-Lanotec-Citra-Force-Aerosol-Declaration-Hazardous-Substance-Meat-Export';
    WebLabel15.ElementFont := efCSS;
    WebLabel15.Font.Charset := DEFAULT_CHARSET;
    WebLabel15.Font.Color := clWindowText;
    WebLabel15.Font.Height := -16;
    WebLabel15.Font.Name := 'Segoe UI';
    WebLabel15.Font.Style := [];
    WebLabel15.HeightStyle := ssAuto;
    WebLabel15.HeightPercent := 10.000000000000000000;
    WebLabel15.ParentFont := False;
    WebLabel15.WordWrap := True;
    WebLabel15.WidthStyle := ssPercent;
    WebLabel15.WidthPercent := 100.000000000000000000;
    WebBrowserControl16.SetParentComponent(WebResponsiveGridPanel16);
    WebBrowserControl16.Name := 'WebBrowserControl16';
    WebBrowserControl16.Left := 0;
    WebBrowserControl16.Top := 0;
    WebBrowserControl16.Width := 280;
    WebBrowserControl16.Height := 375;
    WebBrowserControl16.ElementClassName := 'img2 backGrad1';
    WebBrowserControl16.WidthStyle := ssPercent;
    WebBrowserControl16.HeightPercent := 80.000000000000000000;
    WebBrowserControl16.Align := alTop;
    WebBrowserControl16.ChildOrder := 1;
    WebBrowserControl16.Sandbox := [];
    WebBrowserControl16.URL := 'certs/AQIS-Lanotec-Citra-Force-Aerosol-Declaration-Hazardous-Substance-Meat-Export.pdf';
    WebButton12.SetParentComponent(WebResponsiveGridPanel16);
    WebButton12.Name := 'WebButton12';
    WebButton12.Left := 0;
    WebButton12.Top := 396;
    WebButton12.Width := 140;
    WebButton12.Height := 25;
    WebButton12.Align := alBottom;
    WebButton12.Caption := 'Open';
    WebButton12.ChildOrder := 2;
    WebButton12.ElementClassName := 'btn btn-light';
    WebButton12.ElementFont := efCSS;
    WebButton12.HeightStyle := ssAuto;
    WebButton12.HeightPercent := 10.000000000000000000;
    WebButton12.WidthPercent := 50.000000000000000000;
    SetEvent(WebButton12, Self, 'OnClick', 'WebButton2Click');
    WebResponsiveGridPanel17.SetParentComponent(WebResponsiveGridPanelOuterCerts);
    WebResponsiveGridPanel17.Name := 'WebResponsiveGridPanel17';
    WebResponsiveGridPanel17.Left := 320;
    WebResponsiveGridPanel17.Top := 2655;
    WebResponsiveGridPanel17.Width := 280;
    WebResponsiveGridPanel17.Height := 519;
    WebResponsiveGridPanel17.Anchors := [];
    WebResponsiveGridPanel17.ChildOrder := 1;
    WebResponsiveGridPanel17.ControlCollection.Clear;
    with WebResponsiveGridPanel17.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBrowserControl17;
    end;
    with WebResponsiveGridPanel17.ControlCollection.Add do
    begin
      Column := 0;
      Row := 2;
      Control := WebLabel16;
    end;
    with WebResponsiveGridPanel17.ControlCollection.Add do
    begin
      Column := 0;
      Row := 1;
      Control := WebButton13;
    end;
    WebResponsiveGridPanel17.Color := clWhite;
    WebResponsiveGridPanel17.ElementFont := efCSS;
    WebResponsiveGridPanel17.Layout.Clear;
    with WebResponsiveGridPanel17.Layout.Add do
    begin
      Description := 'Smartphone';
      Style := '1fr';
      Width := 575;
    end;
    with WebResponsiveGridPanel17.Layout.Add do
    begin
      Description := 'Tablet';
      Style := '1fr 1fr';
      Width := 768;
    end;
    with WebResponsiveGridPanel17.Layout.Add do
    begin
      Description := 'Desktop';
      Style := '1fr 1fr 1fr';
      Width := 991;
    end;
    with WebResponsiveGridPanel17.Layout.Add do
    begin
      Description := 'Large Desktop';
      Style := '1fr 1fr 1fr 1fr';
      Width := 1199;
    end;
    WebLabel16.SetParentComponent(WebResponsiveGridPanel17);
    WebLabel16.Name := 'WebLabel16';
    WebLabel16.Left := 0;
    WebLabel16.Top := 375;
    WebLabel16.Width := 280;
    WebLabel16.Height := 21;
    WebLabel16.Align := alTop;
    WebLabel16.Alignment := taCenter;
    WebLabel16.AutoSize := False;
    WebLabel16.Caption := 'AQIS-Lanotec-Citra-Force-2018';
    WebLabel16.ElementFont := efCSS;
    WebLabel16.Font.Charset := DEFAULT_CHARSET;
    WebLabel16.Font.Color := clWindowText;
    WebLabel16.Font.Height := -16;
    WebLabel16.Font.Name := 'Segoe UI';
    WebLabel16.Font.Style := [];
    WebLabel16.HeightStyle := ssAuto;
    WebLabel16.HeightPercent := 10.000000000000000000;
    WebLabel16.ParentFont := False;
    WebLabel16.WordWrap := True;
    WebLabel16.WidthStyle := ssPercent;
    WebLabel16.WidthPercent := 100.000000000000000000;
    WebBrowserControl17.SetParentComponent(WebResponsiveGridPanel17);
    WebBrowserControl17.Name := 'WebBrowserControl17';
    WebBrowserControl17.Left := 0;
    WebBrowserControl17.Top := 0;
    WebBrowserControl17.Width := 280;
    WebBrowserControl17.Height := 375;
    WebBrowserControl17.ElementClassName := 'img2 backGrad1';
    WebBrowserControl17.WidthStyle := ssPercent;
    WebBrowserControl17.HeightPercent := 80.000000000000000000;
    WebBrowserControl17.Align := alTop;
    WebBrowserControl17.ChildOrder := 1;
    WebBrowserControl17.Sandbox := [];
    WebBrowserControl17.URL := 'certs/AQIS-Lanotec-Citra-Force-2018.pdf';
    WebButton13.SetParentComponent(WebResponsiveGridPanel17);
    WebButton13.Name := 'WebButton13';
    WebButton13.Left := 0;
    WebButton13.Top := 396;
    WebButton13.Width := 140;
    WebButton13.Height := 25;
    WebButton13.Align := alBottom;
    WebButton13.Caption := 'Open';
    WebButton13.ChildOrder := 2;
    WebButton13.ElementClassName := 'btn btn-light';
    WebButton13.ElementFont := efCSS;
    WebButton13.HeightStyle := ssAuto;
    WebButton13.HeightPercent := 10.000000000000000000;
    WebButton13.WidthPercent := 50.000000000000000000;
    SetEvent(WebButton13, Self, 'OnClick', 'WebButton2Click');
    WebResponsiveGridPanel18.SetParentComponent(WebResponsiveGridPanelOuterCerts);
    WebResponsiveGridPanel18.Name := 'WebResponsiveGridPanel18';
    WebResponsiveGridPanel18.Left := 10;
    WebResponsiveGridPanel18.Top := 3184;
    WebResponsiveGridPanel18.Width := 280;
    WebResponsiveGridPanel18.Height := 519;
    WebResponsiveGridPanel18.Anchors := [];
    WebResponsiveGridPanel18.ChildOrder := 1;
    WebResponsiveGridPanel18.ControlCollection.Clear;
    with WebResponsiveGridPanel18.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBrowserControl18;
    end;
    with WebResponsiveGridPanel18.ControlCollection.Add do
    begin
      Column := 0;
      Row := 2;
      Control := WebLabel17;
    end;
    with WebResponsiveGridPanel18.ControlCollection.Add do
    begin
      Column := 0;
      Row := 1;
      Control := WebButton14;
    end;
    WebResponsiveGridPanel18.Color := clWhite;
    WebResponsiveGridPanel18.ElementFont := efCSS;
    WebResponsiveGridPanel18.Layout.Clear;
    with WebResponsiveGridPanel18.Layout.Add do
    begin
      Description := 'Smartphone';
      Style := '1fr';
      Width := 575;
    end;
    with WebResponsiveGridPanel18.Layout.Add do
    begin
      Description := 'Tablet';
      Style := '1fr 1fr';
      Width := 768;
    end;
    with WebResponsiveGridPanel18.Layout.Add do
    begin
      Description := 'Desktop';
      Style := '1fr 1fr 1fr';
      Width := 991;
    end;
    with WebResponsiveGridPanel18.Layout.Add do
    begin
      Description := 'Large Desktop';
      Style := '1fr 1fr 1fr 1fr';
      Width := 1199;
    end;
    WebLabel17.SetParentComponent(WebResponsiveGridPanel18);
    WebLabel17.Name := 'WebLabel17';
    WebLabel17.Left := 0;
    WebLabel17.Top := 375;
    WebLabel17.Width := 280;
    WebLabel17.Height := 21;
    WebLabel17.Align := alTop;
    WebLabel17.Alignment := taCenter;
    WebLabel17.AutoSize := False;
    WebLabel17.Caption := 'AQIS-Lanotec-Citra-Force-2018';
    WebLabel17.ElementFont := efCSS;
    WebLabel17.Font.Charset := DEFAULT_CHARSET;
    WebLabel17.Font.Color := clWindowText;
    WebLabel17.Font.Height := -16;
    WebLabel17.Font.Name := 'Segoe UI';
    WebLabel17.Font.Style := [];
    WebLabel17.HeightStyle := ssAuto;
    WebLabel17.HeightPercent := 10.000000000000000000;
    WebLabel17.ParentFont := False;
    WebLabel17.WordWrap := True;
    WebLabel17.WidthStyle := ssPercent;
    WebLabel17.WidthPercent := 100.000000000000000000;
    WebBrowserControl18.SetParentComponent(WebResponsiveGridPanel18);
    WebBrowserControl18.Name := 'WebBrowserControl18';
    WebBrowserControl18.Left := 0;
    WebBrowserControl18.Top := 0;
    WebBrowserControl18.Width := 280;
    WebBrowserControl18.Height := 375;
    WebBrowserControl18.ElementClassName := 'img2 backGrad1';
    WebBrowserControl18.WidthStyle := ssPercent;
    WebBrowserControl18.HeightPercent := 80.000000000000000000;
    WebBrowserControl18.Align := alTop;
    WebBrowserControl18.ChildOrder := 1;
    WebBrowserControl18.Sandbox := [];
    WebBrowserControl18.URL := 'certs/AQIS-Lanotec-Citra-Force-2018.pdf';
    WebButton14.SetParentComponent(WebResponsiveGridPanel18);
    WebButton14.Name := 'WebButton14';
    WebButton14.Left := 0;
    WebButton14.Top := 396;
    WebButton14.Width := 140;
    WebButton14.Height := 25;
    WebButton14.Align := alBottom;
    WebButton14.Caption := 'Open';
    WebButton14.ChildOrder := 2;
    WebButton14.ElementClassName := 'btn btn-light';
    WebButton14.ElementFont := efCSS;
    WebButton14.HeightStyle := ssAuto;
    WebButton14.HeightPercent := 10.000000000000000000;
    WebButton14.WidthPercent := 50.000000000000000000;
    SetEvent(WebButton14, Self, 'OnClick', 'WebButton2Click');
    WebResponsiveGridPanel19.SetParentComponent(WebResponsiveGridPanelOuterCerts);
    WebResponsiveGridPanel19.Name := 'WebResponsiveGridPanel19';
    WebResponsiveGridPanel19.Left := 320;
    WebResponsiveGridPanel19.Top := 3184;
    WebResponsiveGridPanel19.Width := 280;
    WebResponsiveGridPanel19.Height := 519;
    WebResponsiveGridPanel19.Anchors := [];
    WebResponsiveGridPanel19.ChildOrder := 1;
    WebResponsiveGridPanel19.ControlCollection.Clear;
    with WebResponsiveGridPanel19.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBrowserControl19;
    end;
    with WebResponsiveGridPanel19.ControlCollection.Add do
    begin
      Column := 0;
      Row := 2;
      Control := WebLabel18;
    end;
    with WebResponsiveGridPanel19.ControlCollection.Add do
    begin
      Column := 0;
      Row := 1;
      Control := WebButton15;
    end;
    WebResponsiveGridPanel19.Color := clWhite;
    WebResponsiveGridPanel19.ElementFont := efCSS;
    WebResponsiveGridPanel19.Layout.Clear;
    with WebResponsiveGridPanel19.Layout.Add do
    begin
      Description := 'Smartphone';
      Style := '1fr';
      Width := 575;
    end;
    with WebResponsiveGridPanel19.Layout.Add do
    begin
      Description := 'Tablet';
      Style := '1fr 1fr';
      Width := 768;
    end;
    with WebResponsiveGridPanel19.Layout.Add do
    begin
      Description := 'Desktop';
      Style := '1fr 1fr 1fr';
      Width := 991;
    end;
    with WebResponsiveGridPanel19.Layout.Add do
    begin
      Description := 'Large Desktop';
      Style := '1fr 1fr 1fr 1fr';
      Width := 1199;
    end;
    WebLabel18.SetParentComponent(WebResponsiveGridPanel19);
    WebLabel18.Name := 'WebLabel18';
    WebLabel18.Left := 0;
    WebLabel18.Top := 375;
    WebLabel18.Width := 280;
    WebLabel18.Height := 21;
    WebLabel18.Align := alTop;
    WebLabel18.Alignment := taCenter;
    WebLabel18.AutoSize := False;
    WebLabel18.Caption := 'AQIS-Lanotec-Citra-Force-2018';
    WebLabel18.ElementFont := efCSS;
    WebLabel18.Font.Charset := DEFAULT_CHARSET;
    WebLabel18.Font.Color := clWindowText;
    WebLabel18.Font.Height := -16;
    WebLabel18.Font.Name := 'Segoe UI';
    WebLabel18.Font.Style := [];
    WebLabel18.HeightStyle := ssAuto;
    WebLabel18.HeightPercent := 10.000000000000000000;
    WebLabel18.ParentFont := False;
    WebLabel18.WordWrap := True;
    WebLabel18.WidthStyle := ssPercent;
    WebLabel18.WidthPercent := 100.000000000000000000;
    WebBrowserControl19.SetParentComponent(WebResponsiveGridPanel19);
    WebBrowserControl19.Name := 'WebBrowserControl19';
    WebBrowserControl19.Left := 0;
    WebBrowserControl19.Top := 0;
    WebBrowserControl19.Width := 280;
    WebBrowserControl19.Height := 375;
    WebBrowserControl19.ElementClassName := 'img2 backGrad1';
    WebBrowserControl19.WidthStyle := ssPercent;
    WebBrowserControl19.HeightPercent := 80.000000000000000000;
    WebBrowserControl19.Align := alTop;
    WebBrowserControl19.ChildOrder := 1;
    WebBrowserControl19.Sandbox := [];
    WebBrowserControl19.URL := 'certs/AQIS-Lanotec-Citra-Force-2018.pdf';
    WebButton15.SetParentComponent(WebResponsiveGridPanel19);
    WebButton15.Name := 'WebButton15';
    WebButton15.Left := 0;
    WebButton15.Top := 396;
    WebButton15.Width := 140;
    WebButton15.Height := 25;
    WebButton15.Align := alBottom;
    WebButton15.Caption := 'Open';
    WebButton15.ChildOrder := 2;
    WebButton15.ElementClassName := 'btn btn-light';
    WebButton15.ElementFont := efCSS;
    WebButton15.HeightStyle := ssAuto;
    WebButton15.HeightPercent := 10.000000000000000000;
    WebButton15.WidthPercent := 50.000000000000000000;
    SetEvent(WebButton15, Self, 'OnClick', 'WebButton2Click');
    WebResponsiveGridPanel20.SetParentComponent(WebResponsiveGridPanelOuterCerts);
    WebResponsiveGridPanel20.Name := 'WebResponsiveGridPanel20';
    WebResponsiveGridPanel20.Left := 10;
    WebResponsiveGridPanel20.Top := 3713;
    WebResponsiveGridPanel20.Width := 280;
    WebResponsiveGridPanel20.Height := 519;
    WebResponsiveGridPanel20.Anchors := [];
    WebResponsiveGridPanel20.ChildOrder := 1;
    WebResponsiveGridPanel20.ControlCollection.Clear;
    with WebResponsiveGridPanel20.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBrowserControl20;
    end;
    with WebResponsiveGridPanel20.ControlCollection.Add do
    begin
      Column := 0;
      Row := 2;
      Control := WebLabel19;
    end;
    with WebResponsiveGridPanel20.ControlCollection.Add do
    begin
      Column := 0;
      Row := 1;
      Control := WebButton16;
    end;
    WebResponsiveGridPanel20.Color := clWhite;
    WebResponsiveGridPanel20.ElementFont := efCSS;
    WebResponsiveGridPanel20.Layout.Clear;
    with WebResponsiveGridPanel20.Layout.Add do
    begin
      Description := 'Smartphone';
      Style := '1fr';
      Width := 575;
    end;
    with WebResponsiveGridPanel20.Layout.Add do
    begin
      Description := 'Tablet';
      Style := '1fr 1fr';
      Width := 768;
    end;
    with WebResponsiveGridPanel20.Layout.Add do
    begin
      Description := 'Desktop';
      Style := '1fr 1fr 1fr';
      Width := 991;
    end;
    with WebResponsiveGridPanel20.Layout.Add do
    begin
      Description := 'Large Desktop';
      Style := '1fr 1fr 1fr 1fr';
      Width := 1199;
    end;
    WebLabel19.SetParentComponent(WebResponsiveGridPanel20);
    WebLabel19.Name := 'WebLabel19';
    WebLabel19.Left := 0;
    WebLabel19.Top := 375;
    WebLabel19.Width := 280;
    WebLabel19.Height := 21;
    WebLabel19.Align := alTop;
    WebLabel19.Alignment := taCenter;
    WebLabel19.AutoSize := False;
    WebLabel19.Caption := 'AQIS-Lanotec-Citra-Force-2018';
    WebLabel19.ElementFont := efCSS;
    WebLabel19.Font.Charset := DEFAULT_CHARSET;
    WebLabel19.Font.Color := clWindowText;
    WebLabel19.Font.Height := -16;
    WebLabel19.Font.Name := 'Segoe UI';
    WebLabel19.Font.Style := [];
    WebLabel19.HeightStyle := ssAuto;
    WebLabel19.HeightPercent := 10.000000000000000000;
    WebLabel19.ParentFont := False;
    WebLabel19.WordWrap := True;
    WebLabel19.WidthStyle := ssPercent;
    WebLabel19.WidthPercent := 100.000000000000000000;
    WebBrowserControl20.SetParentComponent(WebResponsiveGridPanel20);
    WebBrowserControl20.Name := 'WebBrowserControl20';
    WebBrowserControl20.Left := 0;
    WebBrowserControl20.Top := 0;
    WebBrowserControl20.Width := 280;
    WebBrowserControl20.Height := 375;
    WebBrowserControl20.ElementClassName := 'img2 backGrad1';
    WebBrowserControl20.WidthStyle := ssPercent;
    WebBrowserControl20.HeightPercent := 80.000000000000000000;
    WebBrowserControl20.Align := alTop;
    WebBrowserControl20.ChildOrder := 1;
    WebBrowserControl20.Sandbox := [];
    WebBrowserControl20.URL := 'certs/AQIS-Lanotec-Citra-Force-2018.pdf';
    WebButton16.SetParentComponent(WebResponsiveGridPanel20);
    WebButton16.Name := 'WebButton16';
    WebButton16.Left := 0;
    WebButton16.Top := 396;
    WebButton16.Width := 140;
    WebButton16.Height := 25;
    WebButton16.Align := alBottom;
    WebButton16.Caption := 'Open';
    WebButton16.ChildOrder := 2;
    WebButton16.ElementClassName := 'btn btn-light';
    WebButton16.ElementFont := efCSS;
    WebButton16.HeightStyle := ssAuto;
    WebButton16.HeightPercent := 10.000000000000000000;
    WebButton16.WidthPercent := 50.000000000000000000;
    SetEvent(WebButton16, Self, 'OnClick', 'WebButton2Click');
    WebPageControlEnquiry.SetParentComponent(webControlMain);
    WebPageControlEnquiry.Name := 'WebPageControlEnquiry';
    WebPageControlEnquiry.Left := 0;
    WebPageControlEnquiry.Top := 20;
    WebPageControlEnquiry.Width := 640;
    WebPageControlEnquiry.Height := 4915;
    WebPageControlEnquiry.ElementClassName := 'noBorder1 backGrad2';
    WebPageControlEnquiry.Caption := 'Sales Enquiry';
    WebPageControlEnquiry.ChildOrder := 5;
    WebPageControlEnquiry.ElementFont := efCSS;
    WebHTMLForm2.SetParentComponent(WebPageControlEnquiry);
    WebHTMLForm2.Name := 'WebHTMLForm2';
    WebHTMLForm2.AlignWithMargins := True;
    WebHTMLForm2.Left := 50;
    WebHTMLForm2.Top := 25;
    WebHTMLForm2.Width := 587;
    WebHTMLForm2.Height := 4887;
    WebHTMLForm2.Margins.Left := 50;
    WebHTMLForm2.Margins.Top := 25;
    WebHTMLForm2.HeightStyle := ssPercent;
    WebHTMLForm2.Action := '';
    WebHTMLForm2.Align := alClient;
    WebHTMLForm2.ElementFont := efCSS;
    SetEvent(WebHTMLForm2, Self, 'OnSubmit', 'WebHTMLForm2Submit');
    WebScrollBox3.SetParentComponent(WebHTMLForm2);
    WebScrollBox3.Name := 'WebScrollBox3';
    WebScrollBox3.Left := 0;
    WebScrollBox3.Top := 0;
    WebScrollBox3.Width := 587;
    WebScrollBox3.Height := 4887;
    WebScrollBox3.ElementClassName := 'noBorder1';
    WebScrollBox3.Align := alClient;
    WebScrollBox3.BorderStyle := bsSingle;
    WebScrollBox3.Color := clWindow;
    WebResponsiveGridPanel5.SetParentComponent(WebScrollBox3);
    WebResponsiveGridPanel5.Name := 'WebResponsiveGridPanel5';
    WebResponsiveGridPanel5.AlignWithMargins := True;
    WebResponsiveGridPanel5.Left := 0;
    WebResponsiveGridPanel5.Top := 0;
    WebResponsiveGridPanel5.Width := 537;
    WebResponsiveGridPanel5.Height := 4884;
    WebResponsiveGridPanel5.Margins.Left := 0;
    WebResponsiveGridPanel5.Margins.Top := 0;
    WebResponsiveGridPanel5.Margins.Right := 50;
    WebResponsiveGridPanel5.ElementClassName := 'noBorder1';
    WebResponsiveGridPanel5.Align := alClient;
    WebResponsiveGridPanel5.ControlCollection.Clear;
    with WebResponsiveGridPanel5.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebLabel2;
    end;
    with WebResponsiveGridPanel5.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := webEditFirstName;
    end;
    with WebResponsiveGridPanel5.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := webEditSurname;
    end;
    with WebResponsiveGridPanel5.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := webEditMobile;
    end;
    with WebResponsiveGridPanel5.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := webEditEmail;
    end;
    with WebResponsiveGridPanel5.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := webMemoExperience;
    end;
    with WebResponsiveGridPanel5.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebButton1;
    end;
    with WebResponsiveGridPanel5.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebPanel3;
    end;
    WebResponsiveGridPanel5.Color := clNone;
    WebResponsiveGridPanel5.ElementFont := efCSS;
    WebResponsiveGridPanel5.ElementPosition := epRelative;
    WebResponsiveGridPanel5.Layout.Clear;
    with WebResponsiveGridPanel5.Layout.Add do
    begin
      Description := 'Smartphone';
      Style := '1fr';
      Width := 300;
    end;
    with WebResponsiveGridPanel5.Layout.Add do
    begin
      Description := 'desktop';
      Style := '1fr';
      Width := 700;
    end;
    WebLabel2.SetParentComponent(WebResponsiveGridPanel5);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.AlignWithMargins := True;
    WebLabel2.Left := 0;
    WebLabel2.Top := 0;
    WebLabel2.Width := 171;
    WebLabel2.Height := 15;
    WebLabel2.Margins.Top := 10;
    WebLabel2.Margins.Bottom := 10;
    WebLabel2.Alignment := taCenter;
    WebLabel2.Anchors := [];
    WebLabel2.Caption := ' Please enter your details below :';
    WebLabel2.Color := clNone;
    WebLabel2.ElementClassName := ' backGrad1';
    WebLabel2.ElementFont := efCSS;
    WebLabel2.HeightStyle := ssAuto;
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    webEditFirstName.SetParentComponent(WebResponsiveGridPanel5);
    webEditFirstName.Name := 'webEditFirstName';
    webEditFirstName.Left := 0;
    webEditFirstName.Top := 15;
    webEditFirstName.Width := 250;
    webEditFirstName.Height := 40;
    webEditFirstName.Margins.Top := 5;
    webEditFirstName.Margins.Bottom := 5;
    webEditFirstName.Anchors := [];
    webEditFirstName.AutoCompletion := acCCGivenName;
    webEditFirstName.CharCase := wecMixedCase;
    webEditFirstName.ChildOrder := 2;
    webEditFirstName.ElementClassName := 'form-control  backGrad1';
    webEditFirstName.ElementFont := efCSS;
    webEditFirstName.ElementPosition := epIgnore;
    webEditFirstName.HeightStyle := ssAuto;
    webEditFirstName.HeightPercent := 100.000000000000000000;
    webEditFirstName.Required := True;
    webEditFirstName.ShowHint := True;
    webEditFirstName.TextHint := 'First Name';
    webEditFirstName.WidthStyle := ssAuto;
    webEditFirstName.WidthPercent := 75.000000000000000000;
    webEditSurname.SetParentComponent(WebResponsiveGridPanel5);
    webEditSurname.Name := 'webEditSurname';
    webEditSurname.Left := 0;
    webEditSurname.Top := 55;
    webEditSurname.Width := 250;
    webEditSurname.Height := 40;
    webEditSurname.Hint := 'Surname';
    webEditSurname.Margins.Top := 5;
    webEditSurname.Margins.Bottom := 5;
    webEditSurname.Anchors := [];
    webEditSurname.AutoCompletion := acFamilyName;
    webEditSurname.CharCase := wecMixedCase;
    webEditSurname.ChildOrder := 2;
    webEditSurname.ElementClassName := 'form-control backGrad1';
    webEditSurname.ElementFont := efCSS;
    webEditSurname.ElementPosition := epIgnore;
    webEditSurname.HeightStyle := ssAuto;
    webEditSurname.HeightPercent := 100.000000000000000000;
    webEditSurname.Required := True;
    webEditSurname.ShowHint := True;
    webEditSurname.TextHint := 'Surname';
    webEditSurname.WidthStyle := ssAuto;
    webEditSurname.WidthPercent := 75.000000000000000000;
    webEditMobile.SetParentComponent(WebResponsiveGridPanel5);
    webEditMobile.Name := 'webEditMobile';
    webEditMobile.Left := 0;
    webEditMobile.Top := 95;
    webEditMobile.Width := 250;
    webEditMobile.Height := 40;
    webEditMobile.Margins.Top := 5;
    webEditMobile.Margins.Bottom := 5;
    webEditMobile.Anchors := [];
    webEditMobile.AutoCompletion := acTelephone;
    webEditMobile.CharCase := wecMixedCase;
    webEditMobile.ChildOrder := 2;
    webEditMobile.EditType := weNumeric;
    webEditMobile.ElementClassName := 'form-control backGrad1';
    webEditMobile.ElementFont := efCSS;
    webEditMobile.ElementPosition := epIgnore;
    webEditMobile.HeightStyle := ssAuto;
    webEditMobile.HeightPercent := 100.000000000000000000;
    webEditMobile.Required := True;
    webEditMobile.ShowHint := True;
    webEditMobile.TextHint := 'Tel';
    webEditMobile.WidthStyle := ssAuto;
    webEditMobile.WidthPercent := 75.000000000000000000;
    webEditEmail.SetParentComponent(WebResponsiveGridPanel5);
    webEditEmail.Name := 'webEditEmail';
    webEditEmail.Left := 0;
    webEditEmail.Top := 135;
    webEditEmail.Width := 250;
    webEditEmail.Height := 40;
    webEditEmail.Margins.Top := 5;
    webEditEmail.Margins.Bottom := 5;
    webEditEmail.Anchors := [];
    webEditEmail.AutoCompletion := acEmail;
    webEditEmail.ChildOrder := 2;
    webEditEmail.ElementClassName := 'form-control backGrad1';
    webEditEmail.ElementFont := efCSS;
    webEditEmail.ElementPosition := epIgnore;
    webEditEmail.HeightStyle := ssAuto;
    webEditEmail.HeightPercent := 100.000000000000000000;
    webEditEmail.Required := True;
    webEditEmail.ShowHint := True;
    webEditEmail.TextHint := 'Email';
    webEditEmail.WidthStyle := ssAuto;
    webEditEmail.WidthPercent := 75.000000000000000000;
    webMemoExperience.SetParentComponent(WebResponsiveGridPanel5);
    webMemoExperience.Name := 'webMemoExperience';
    webMemoExperience.Left := 0;
    webMemoExperience.Top := 175;
    webMemoExperience.Width := 534;
    webMemoExperience.Height := 102;
    webMemoExperience.Margins.Top := 5;
    webMemoExperience.Margins.Bottom := 5;
    webMemoExperience.Anchors := [];
    webMemoExperience.AutoSize := False;
    webMemoExperience.ElementClassName := 'form-control backGrad1';
    webMemoExperience.ElementFont := efCSS;
    webMemoExperience.HeightPercent := 100.000000000000000000;
    webMemoExperience.SelLength := 0;
    webMemoExperience.SelStart := 0;
    webMemoExperience.ShowHint := True;
    webMemoExperience.TextHint := 'Product Details';
    webMemoExperience.WidthPercent := 75.000000000000000000;
    WebButton1.SetParentComponent(WebResponsiveGridPanel5);
    WebButton1.Name := 'WebButton1';
    WebButton1.AlignWithMargins := True;
    WebButton1.Left := 0;
    WebButton1.Top := 277;
    WebButton1.Width := 137;
    WebButton1.Height := 25;
    WebButton1.Margins.Bottom := 200;
    WebButton1.Anchors := [];
    WebButton1.ButtonType := 'submit';
    WebButton1.Caption := 'Send Enquiry';
    WebButton1.ChildOrder := 11;
    WebButton1.ElementClassName := 'btn btn-light backGrad1';
    WebButton1.ElementFont := efCSS;
    WebButton1.ElementPosition := epIgnore;
    WebButton1.HeightStyle := ssAuto;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthStyle := ssAuto;
    WebButton1.WidthPercent := 100.000000000000000000;
    WebPanel3.SetParentComponent(WebResponsiveGridPanel5);
    WebPanel3.Name := 'WebPanel3';
    WebPanel3.Left := 0;
    WebPanel3.Top := 302;
    WebPanel3.Width := 537;
    WebPanel3.Height := 50;
    WebPanel3.ElementClassName := 'card';
    WebPanel3.HeightStyle := ssAuto;
    WebPanel3.WidthStyle := ssPercent;
    WebPanel3.Anchors := [];
    WebPanel3.BorderColor := clNone;
    WebPanel3.BorderStyle := bsNone;
    WebPanel3.Caption := 'We will respond to your enquiry within 24 hours.';
    WebPanel3.ChildOrder := 13;
    WebPanel3.Color := clWindow;
    WebPanel3.ElementBodyClassName := 'card-body';
    WebPanel3.ElementFont := efCSS;
    WebPanel3.ElementPosition := epIgnore;
    WebPageControlContacts.SetParentComponent(webControlMain);
    WebPageControlContacts.Name := 'WebPageControlContacts';
    WebPageControlContacts.Left := 0;
    WebPageControlContacts.Top := 20;
    WebPageControlContacts.Width := 640;
    WebPageControlContacts.Height := 4915;
    WebPageControlContacts.ElementClassName := 'noBorder1 backGrad2';
    WebPageControlContacts.Caption := 'Contacts';
    WebPageControlContacts.ChildOrder := 15;
    WebPageControlContacts.ElementFont := efCSS;
    WebScrollBox2.SetParentComponent(WebPageControlContacts);
    WebScrollBox2.Name := 'WebScrollBox2';
    WebScrollBox2.Left := 0;
    WebScrollBox2.Top := 0;
    WebScrollBox2.Width := 640;
    WebScrollBox2.Height := 4915;
    WebScrollBox2.ElementClassName := 'noBorder1';
    WebScrollBox2.Align := alClient;
    WebScrollBox2.BorderStyle := bsSingle;
    WebScrollBox2.ChildOrder := 1;
    WebScrollBox2.Color := clWindow;
    WebResponsiveGridPanel4.SetParentComponent(WebScrollBox2);
    WebResponsiveGridPanel4.Name := 'WebResponsiveGridPanel4';
    WebResponsiveGridPanel4.AlignWithMargins := True;
    WebResponsiveGridPanel4.Left := 50;
    WebResponsiveGridPanel4.Top := 25;
    WebResponsiveGridPanel4.Width := 587;
    WebResponsiveGridPanel4.Height := 4887;
    WebResponsiveGridPanel4.Margins.Left := 50;
    WebResponsiveGridPanel4.Margins.Top := 25;
    WebResponsiveGridPanel4.HeightStyle := ssAuto;
    WebResponsiveGridPanel4.WidthStyle := ssAuto;
    WebResponsiveGridPanel4.Align := alClient;
    WebResponsiveGridPanel4.Anchors := [];
    WebResponsiveGridPanel4.ChildOrder := 6;
    WebResponsiveGridPanel4.ControlCollection.Clear;
    with WebResponsiveGridPanel4.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebLabel3;
    end;
    with WebResponsiveGridPanel4.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBitBtn3;
    end;
    with WebResponsiveGridPanel4.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBitBtn4;
    end;
    with WebResponsiveGridPanel4.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebLabel4;
    end;
    with WebResponsiveGridPanel4.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBitBtn1;
    end;
    with WebResponsiveGridPanel4.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBitBtn2;
    end;
    with WebResponsiveGridPanel4.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebLabel5;
    end;
    with WebResponsiveGridPanel4.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBitBtn7;
    end;
    with WebResponsiveGridPanel4.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBitBtn5;
    end;
    with WebResponsiveGridPanel4.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebBitBtn6;
    end;
    WebResponsiveGridPanel4.Color := clNone;
    WebResponsiveGridPanel4.ElementFont := efCSS;
    WebResponsiveGridPanel4.Layout.Clear;
    with WebResponsiveGridPanel4.Layout.Add do
    begin
      Description := 'Smartphone';
      Style := '1fr';
      Width := 575;
    end;
    WebLabel3.SetParentComponent(WebResponsiveGridPanel4);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 0;
    WebLabel3.Top := 0;
    WebLabel3.Width := 50;
    WebLabel3.Height := 15;
    WebLabel3.Alignment := taCenter;
    WebLabel3.Anchors := [];
    WebLabel3.Caption := 'Who ever';
    WebLabel3.Color := clNone;
    WebLabel3.ElementFont := efCSS;
    WebLabel3.HeightStyle := ssAuto;
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(WebResponsiveGridPanel4);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 0;
    WebLabel4.Top := 95;
    WebLabel4.Width := 88;
    WebLabel4.Height := 15;
    WebLabel4.Anchors := [];
    WebLabel4.Caption := 'Steve Warburton';
    WebLabel4.Color := clNone;
    WebLabel4.ElementFont := efCSS;
    WebLabel4.HeightStyle := ssAuto;
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(WebResponsiveGridPanel4);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 0;
    WebLabel5.Top := 190;
    WebLabel5.Width := 67;
    WebLabel5.Height := 15;
    WebLabel5.Anchors := [];
    WebLabel5.Caption := 'Social Media';
    WebLabel5.Color := clNone;
    WebLabel5.ElementFont := efCSS;
    WebLabel5.HeightStyle := ssAuto;
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebBitBtn3.SetParentComponent(WebResponsiveGridPanel4);
    WebBitBtn3.Name := 'WebBitBtn3';
    WebBitBtn3.AlignWithMargins := True;
    WebBitBtn3.Left := 0;
    WebBitBtn3.Top := 15;
    WebBitBtn3.Width := 250;
    WebBitBtn3.Height := 40;
    WebBitBtn3.Margins.Left := 100;
    WebBitBtn3.Margins.Bottom := 100;
    WebBitBtn3.Alignment := taLeftJustify;
    WebBitBtn3.Anchors := [];
    WebBitBtn3.Caption := 'Sales@lanotec.uk';
    WebBitBtn3.Color := clActiveBorder;
    WebBitBtn3.ElementClassName := 'btn btn-light backGrad1';
    WebBitBtn3.ElementFont := efCSS;
    WebBitBtn3.GlyphURL := 'videoThumbnails/emailIcon.png';
    WebBitBtn3.HeightStyle := ssAuto;
    WebBitBtn3.HeightPercent := 100.000000000000000000;
    WebBitBtn3.WidthPercent := 100.000000000000000000;
    WebBitBtn4.SetParentComponent(WebResponsiveGridPanel4);
    WebBitBtn4.Name := 'WebBitBtn4';
    WebBitBtn4.Left := 0;
    WebBitBtn4.Top := 55;
    WebBitBtn4.Width := 250;
    WebBitBtn4.Height := 40;
    WebBitBtn4.Alignment := taLeftJustify;
    WebBitBtn4.Anchors := [];
    WebBitBtn4.Caption := '070000000';
    WebBitBtn4.Color := clNone;
    WebBitBtn4.ElementClassName := 'btn btn-light backGrad1';
    WebBitBtn4.ElementFont := efCSS;
    WebBitBtn4.GlyphURL := 'videoThumbnails/telIcon.png';
    WebBitBtn4.HeightStyle := ssAuto;
    WebBitBtn4.HeightPercent := 100.000000000000000000;
    WebBitBtn4.WidthPercent := 100.000000000000000000;
    WebBitBtn1.SetParentComponent(WebResponsiveGridPanel4);
    WebBitBtn1.Name := 'WebBitBtn1';
    WebBitBtn1.Left := 0;
    WebBitBtn1.Top := 110;
    WebBitBtn1.Width := 250;
    WebBitBtn1.Height := 40;
    WebBitBtn1.Alignment := taLeftJustify;
    WebBitBtn1.Anchors := [];
    WebBitBtn1.Caption := 'Admin@lanotec.uk';
    WebBitBtn1.Color := clHighlight;
    WebBitBtn1.ElementClassName := 'btn btn-light backGrad1';
    WebBitBtn1.ElementFont := efCSS;
    WebBitBtn1.GlyphURL := 'videoThumbnails/emailIcon.png';
    WebBitBtn1.HeightStyle := ssAuto;
    WebBitBtn1.HeightPercent := 100.000000000000000000;
    WebBitBtn1.WidthPercent := 100.000000000000000000;
    WebBitBtn2.SetParentComponent(WebResponsiveGridPanel4);
    WebBitBtn2.Name := 'WebBitBtn2';
    WebBitBtn2.Left := 0;
    WebBitBtn2.Top := 150;
    WebBitBtn2.Width := 250;
    WebBitBtn2.Height := 40;
    WebBitBtn2.Alignment := taLeftJustify;
    WebBitBtn2.Anchors := [];
    WebBitBtn2.Caption := '07700000';
    WebBitBtn2.Color := clNone;
    WebBitBtn2.ElementClassName := 'btn btn-light backGrad1';
    WebBitBtn2.ElementFont := efCSS;
    WebBitBtn2.GlyphURL := 'videoThumbnails/telIcon.png';
    WebBitBtn2.HeightStyle := ssAuto;
    WebBitBtn2.HeightPercent := 100.000000000000000000;
    WebBitBtn2.WidthPercent := 100.000000000000000000;
    WebBitBtn5.SetParentComponent(WebResponsiveGridPanel4);
    WebBitBtn5.Name := 'WebBitBtn5';
    WebBitBtn5.Left := 0;
    WebBitBtn5.Top := 245;
    WebBitBtn5.Width := 250;
    WebBitBtn5.Height := 40;
    WebBitBtn5.Alignment := taLeftJustify;
    WebBitBtn5.Anchors := [];
    WebBitBtn5.Caption := 'TikTok';
    WebBitBtn5.Color := clNone;
    WebBitBtn5.ElementClassName := 'btn btn-light backGrad1';
    WebBitBtn5.ElementFont := efCSS;
    WebBitBtn5.GlyphURL := 'videoThumbnails/tiktokIcon.png';
    WebBitBtn5.HeightStyle := ssAuto;
    WebBitBtn5.HeightPercent := 100.000000000000000000;
    WebBitBtn5.WidthPercent := 100.000000000000000000;
    WebBitBtn6.SetParentComponent(WebResponsiveGridPanel4);
    WebBitBtn6.Name := 'WebBitBtn6';
    WebBitBtn6.Left := 0;
    WebBitBtn6.Top := 285;
    WebBitBtn6.Width := 250;
    WebBitBtn6.Height := 40;
    WebBitBtn6.Alignment := taLeftJustify;
    WebBitBtn6.Anchors := [];
    WebBitBtn6.Caption := 'YouTube';
    WebBitBtn6.Color := clNone;
    WebBitBtn6.ElementClassName := 'btn btn-light backGrad1';
    WebBitBtn6.ElementFont := efCSS;
    WebBitBtn6.GlyphURL := 'videoThumbnails/youTubeIcon.png';
    WebBitBtn6.HeightStyle := ssAuto;
    WebBitBtn6.HeightPercent := 100.000000000000000000;
    WebBitBtn6.WidthPercent := 100.000000000000000000;
    WebBitBtn7.SetParentComponent(WebResponsiveGridPanel4);
    WebBitBtn7.Name := 'WebBitBtn7';
    WebBitBtn7.Left := 0;
    WebBitBtn7.Top := 205;
    WebBitBtn7.Width := 250;
    WebBitBtn7.Height := 40;
    WebBitBtn7.Alignment := taLeftJustify;
    WebBitBtn7.Anchors := [];
    WebBitBtn7.Caption := 'Facebook';
    WebBitBtn7.Color := clNone;
    WebBitBtn7.ElementClassName := 'btn btn-light backGrad1';
    WebBitBtn7.ElementFont := efCSS;
    WebBitBtn7.GlyphURL := 'videoThumbnails/facebookIcon.png';
    WebBitBtn7.HeightStyle := ssAuto;
    WebBitBtn7.HeightPercent := 100.000000000000000000;
    WebBitBtn7.WidthPercent := 100.000000000000000000;
    WebResponsiveGridPanel1.SetParentComponent(Self);
    WebResponsiveGridPanel1.Name := 'WebResponsiveGridPanel1';
    WebResponsiveGridPanel1.Left := 0;
    WebResponsiveGridPanel1.Top := 0;
    WebResponsiveGridPanel1.Width := 640;
    WebResponsiveGridPanel1.Height := 65;
    WebResponsiveGridPanel1.Align := alTop;
    WebResponsiveGridPanel1.ChildOrder := 3;
    WebResponsiveGridPanel1.ControlCollection.Clear;
    with WebResponsiveGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebImageControl1;
    end;
    WebResponsiveGridPanel1.Color := clNone;
    WebResponsiveGridPanel1.ElementFont := efCSS;
    WebResponsiveGridPanel1.Layout.Clear;
    with WebResponsiveGridPanel1.Layout.Add do
    begin
      Description := 'Smartphone';
      Style := '1fr';
      Width := 575;
    end;
    with WebResponsiveGridPanel1.Layout.Add do
    begin
      Description := 'Tablet';
      Style := '1fr';
      Width := 768;
    end;
    with WebResponsiveGridPanel1.Layout.Add do
    begin
      Description := 'Desktop';
      Style := '1fr';
      Width := 991;
    end;
    with WebResponsiveGridPanel1.Layout.Add do
    begin
      Description := 'Large Desktop';
      Style := '1fr';
      Width := 1199;
    end;
    SetEvent(WebResponsiveGridPanel1, Self, 'OnLayoutChange', 'WebResponsiveGridPanel1LayoutChange');
    WebImageControl1.SetParentComponent(WebResponsiveGridPanel1);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 0;
    WebImageControl1.Top := 0;
    WebImageControl1.Width := 637;
    WebImageControl1.Height := 42;
    WebImageControl1.WidthStyle := ssAuto;
    WebImageControl1.HeightPercent := 100.000000000000000000;
    WebImageControl1.WidthPercent := 100.000000000000000000;
    WebImageControl1.Align := alClient;
    WebImageControl1.URL := 'videoThumbnails/logo20.png';
  finally
    webControlMain.AfterLoadDFMValues;
    WebPageControlIntroduction.AfterLoadDFMValues;
    WebScrollBox1.AfterLoadDFMValues;
    WebResponsiveGridPanel2.AfterLoadDFMValues;
    WebMultimediaPlayer1.AfterLoadDFMValues;
    WebMultimediaPlayer2.AfterLoadDFMValues;
    WebMultimediaPlayer3.AfterLoadDFMValues;
    WebMultimediaPlayer4.AfterLoadDFMValues;
    WebMultimediaPlayer5.AfterLoadDFMValues;
    WebMultimediaPlayer6.AfterLoadDFMValues;
    WebMultimediaPlayer7.AfterLoadDFMValues;
    WebMultimediaPlayer8.AfterLoadDFMValues;
    webPageControlTestimonials.AfterLoadDFMValues;
    WebScrollBox4.AfterLoadDFMValues;
    WebResponsiveGridPanel3.AfterLoadDFMValues;
    WebBrowserControl1.AfterLoadDFMValues;
    WebBrowserControl2.AfterLoadDFMValues;
    WebBrowserControl3.AfterLoadDFMValues;
    WebBrowserControl4.AfterLoadDFMValues;
    WebBrowserControl5.AfterLoadDFMValues;
    webPageControlCertification.AfterLoadDFMValues;
    WebScrollBox5.AfterLoadDFMValues;
    WebResponsiveGridPanelOuterCerts.AfterLoadDFMValues;
    WebResponsiveGridPanel6.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebBrowserControl6.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebResponsiveGridPanel7.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebBrowserControl7.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebResponsiveGridPanel8.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebBrowserControl8.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebResponsiveGridPanel9.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebBrowserControl9.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebResponsiveGridPanel10.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebBrowserControl10.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
    WebResponsiveGridPanel11.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    WebBrowserControl11.AfterLoadDFMValues;
    WebButton7.AfterLoadDFMValues;
    WebResponsiveGridPanel12.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    WebBrowserControl12.AfterLoadDFMValues;
    WebButton8.AfterLoadDFMValues;
    WebResponsiveGridPanel13.AfterLoadDFMValues;
    WebLabel12.AfterLoadDFMValues;
    WebBrowserControl13.AfterLoadDFMValues;
    WebButton9.AfterLoadDFMValues;
    WebResponsiveGridPanel14.AfterLoadDFMValues;
    WebLabel13.AfterLoadDFMValues;
    WebBrowserControl14.AfterLoadDFMValues;
    WebButton10.AfterLoadDFMValues;
    WebResponsiveGridPanel15.AfterLoadDFMValues;
    WebLabel14.AfterLoadDFMValues;
    WebBrowserControl15.AfterLoadDFMValues;
    WebButton11.AfterLoadDFMValues;
    WebResponsiveGridPanel16.AfterLoadDFMValues;
    WebLabel15.AfterLoadDFMValues;
    WebBrowserControl16.AfterLoadDFMValues;
    WebButton12.AfterLoadDFMValues;
    WebResponsiveGridPanel17.AfterLoadDFMValues;
    WebLabel16.AfterLoadDFMValues;
    WebBrowserControl17.AfterLoadDFMValues;
    WebButton13.AfterLoadDFMValues;
    WebResponsiveGridPanel18.AfterLoadDFMValues;
    WebLabel17.AfterLoadDFMValues;
    WebBrowserControl18.AfterLoadDFMValues;
    WebButton14.AfterLoadDFMValues;
    WebResponsiveGridPanel19.AfterLoadDFMValues;
    WebLabel18.AfterLoadDFMValues;
    WebBrowserControl19.AfterLoadDFMValues;
    WebButton15.AfterLoadDFMValues;
    WebResponsiveGridPanel20.AfterLoadDFMValues;
    WebLabel19.AfterLoadDFMValues;
    WebBrowserControl20.AfterLoadDFMValues;
    WebButton16.AfterLoadDFMValues;
    WebPageControlEnquiry.AfterLoadDFMValues;
    WebHTMLForm2.AfterLoadDFMValues;
    WebScrollBox3.AfterLoadDFMValues;
    WebResponsiveGridPanel5.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    webEditFirstName.AfterLoadDFMValues;
    webEditSurname.AfterLoadDFMValues;
    webEditMobile.AfterLoadDFMValues;
    webEditEmail.AfterLoadDFMValues;
    webMemoExperience.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebPanel3.AfterLoadDFMValues;
    WebPageControlContacts.AfterLoadDFMValues;
    WebScrollBox2.AfterLoadDFMValues;
    WebResponsiveGridPanel4.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebBitBtn3.AfterLoadDFMValues;
    WebBitBtn4.AfterLoadDFMValues;
    WebBitBtn1.AfterLoadDFMValues;
    WebBitBtn2.AfterLoadDFMValues;
    WebBitBtn5.AfterLoadDFMValues;
    WebBitBtn6.AfterLoadDFMValues;
    WebBitBtn7.AfterLoadDFMValues;
    WebResponsiveGridPanel1.AfterLoadDFMValues;
    WebImageControl1.AfterLoadDFMValues;
  end;
end;

end.

//procedure TForm7.WebBitBtn1Click(Sender: TObject);
//begin
//    Application.Navigate('mailto:admin@rockstruck.com')
//end;
//
//procedure TForm7.WebBitBtn2Click(Sender: TObject);
//begin
//    Application.Navigate('tel:5018596424') ;
//end;
//
//procedure TForm7.WebBitBtn3Click(Sender: TObject);
//begin
//       Application.Navigate('mailto:sales@rockstruck.com') ;
//end;
//
//procedure TForm7.WebBitBtn4Click(Sender: TObject);
//begin
//      Application.Navigate('tel:5155694735') ;
//end;
//
//procedure TForm7.WebBitBtn5Click(Sender: TObject);
//begin
//   // <a href="https://www.WordPress.com" target="_blank">WordPress Homepage</a>
//    Application.Navigate('https://www.tiktok.com/@rockstruck') ;
//end;
//
//procedure TForm7.WebBitBtn6Click(Sender: TObject);
//begin
//      Application.Navigate('https://www.youtube.com/channel/UCiOh2yCw_qrMTxLhKAjh5_g') ;
//end;
//
//procedure TForm7.WebBitBtn7Click(Sender: TObject);
//begin
//    Application.Navigate('https://www.facebook.com/RocksTruck-107772335268987') ;
//
//end;
