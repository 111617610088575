program lanotec;

{$R *.dres}

uses
  Vcl.Forms,
  WEBLib.Forms,
  Unit8 in 'Unit8.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateFormDirect(TForm7, Form7);
  Application.Run;
end.
