{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.Mask;

{$DEFINE NOPP}

interface

uses
  Classes, SysUtils, StrUtils, WEBLib.Controls, WEBLib.StdCtrls, Web;

type
  TMaskedState = set of (mStateMasked, mStateReEnter, mStateDBSetText);

  TMaskedText = type string;
  TEditMask = type string;

  TCustomMaskEdit = class(TEdit)
  private
    FEditMask: TEditMask;
    FMaskBlank: Char;
    FMaxChars: Integer;
    FMaskState: TMaskedState;
    FCaretPos: Integer;
    FOldValue: string;
    FEditText: string;
    function GetEditText: string;
    procedure SetEditText(const Value: string);
    function AddEditFormat(const Value: string; Active: Boolean): string;
    function RemoveEditFormat(const Value: string): string;
    procedure ArrowKeys(CharCode: Word; Shift: TShiftState);
    procedure CursorInc(CursorPos: Integer; Incr: Integer);
    function CursorDec(CursorPos: Integer): Integer;
    procedure DeleteKeys(CharCode: Word);
    function DeleteSelection(var Value: string; Offset: Integer;
      Len: Integer): Boolean;
    procedure HomeEndKeys(CharCode: Word; Shift: TShiftState);
    function CharKeys(var CharCode: Char): Boolean;
    function InputChar(var NewChar: Char; Offset: Integer): Boolean;
    function DoInputChar(var NewChar: Char; MaskOffset: Integer): Boolean;
    function FindLiteralChar (MaskOffset: Integer; InChar: Char): Integer;
  protected
    procedure SetEditMask(const Value: TEditMask);
    function GetMasked: Boolean;
    function GetTextLen: integer;
    function EditCanModify: boolean; virtual;
    procedure ReformatText(const NewMask: string);
    procedure KeyDown(var Key: Word; Shift: TShiftState); override;
    procedure KeyUp(var Key: Word; Shift: TShiftState); override;
    procedure KeyPress(var Key: Char); override;
    procedure MouseUp(Button: TMouseButton; Shift: TShiftState; X,Y: Integer); override;
    procedure CheckCursor;
    procedure Reset; virtual;
    procedure Loaded; override;
    procedure DoEnter; override;
  public
    procedure CreateInitialize; override;
    procedure GetSel(var ASelStart: Integer; var ASelStop: Integer);
    procedure SetSel(ASelStart: Integer; ASelStop: Integer);
    procedure SetCursor(Pos: Integer);

    property EditMask: TEditMask read FEditMask write SetEditMask;
    property EditText: string read GetEditText write SetEditText;

    function GetFirstEditChar: Integer;
    function GetLastEditChar: Integer;
    function GetNextEditChar(Offset: Integer): Integer;
    function GetPriorEditChar(Offset: Integer): Integer;
    function GetMaxChars: Integer;
    property IsMasked: Boolean read GetMasked;
  end;

  TMaskEdit = class(TCustomMaskEdit)
  published
    property EditMask;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
  end;

  TWebMaskEdit = class(TMaskEdit);

implementation

uses
  Math, WEBLib.WebTools;

type
  TMaskCharType = (mcNone, mcLiteral, mcIntlLiteral, mcDirective, mcMask,
    mcMaskOpt, mcFieldSeparator, mcField);

  TMaskInstructions = set of (miReverseDir, miUpperCase, miLowerCase,
    miLiteralChar);

const
  dMaskReverse = '!';
  dMaskUpperCase = '>';
  dMaskLowerCase = '<';
  dMaskLiteral = '\';

  cMaskAlpha = 'L';
  cMaskAlphaOpt = 'l';
  cMaskAlphaNum = 'A';
  cMaskAlphaNumOpt  = 'a';
  cMaskAscii = 'C';
  cMaskAsciiOpt = 'c';
  cMaskNumeric = '0';
  cMaskNumericOpt = '9';
  cMaskNumSymOpt = '#';
  cMaskTimeSeparator = ':';
  cMaskDateSeparator = '/';
  mskSeparator = ';';
  mskBlank = '_';

function IsCharAlpha(IChar: Char): boolean;
begin
  Result := ((IChar >= 'a') and (IChar <= 'z')) or
     ((IChar >= 'A') and (IChar <= 'Z'));
end;

function IsCharAlphaNumeric(IChar: Char): boolean;
begin
  Result := ((IChar >= 'a') and (IChar <= 'z')) or
     ((IChar >= 'A') and (IChar <= 'Z')) or
     ((IChar >= '0') and (IChar <= '9'));
end;

function GetMaskCharType(const EditMask: string; MaskOffset: Integer): TMaskCharType;
var
  MaskChar: Char;
begin
  Result := mcLiteral;
  MaskChar := #0;
  if MaskOffset <= Length(EditMask) then
    MaskChar := EditMask[MaskOffset];
  if MaskOffset > Length(EditMask) then
    Result := mcNone
  else if (MaskOffset > 1) and (EditMask[MaskOffset - 1] = dMaskLiteral) and
      not ((MaskOffset > 2) and (EditMask[MaskOffset - 2] = dMaskLiteral)) then
    Result := mcLiteral
  else if (MaskChar = mskSeparator) and
         (Length(EditMask) >= 4) and
         (MaskOffset > Length(EditMask) - 4) then
    Result := mcFieldSeparator
  else if (Length(EditMask) >= 4) and
         (MaskOffset > (Length(EditMask) - 4)) and
         (EditMask[MaskOffset - 1] = mskSeparator) and
         not ((MaskOffset > 2) and (EditMask[MaskOffset - 2] = dMaskLiteral)) then
    Result := mcField

  else if MaskChar in [cMaskTimeSeparator, cMaskDateSeparator] then
    Result := mcIntlLiteral

  else if MaskChar in [dMaskReverse, dMaskUpperCase, dMaskLowerCase,
      dMaskLiteral] then
    Result := mcDirective

  else if MaskChar in [cMaskAlphaOpt, cMaskAlphaNumOpt, cMaskAsciiOpt,
      cMaskNumSymOpt, cMaskNumericOpt] then
    Result := mcMaskOpt

  else if MaskChar in [cMaskAlpha, cMaskAlphaNum, cMaskAscii, cMaskNumeric] then
    Result := mcMask;
end;


function GetMaskInstructions(const EditMask: string; MaskOffset: Integer): TMaskInstructions;
var
  I: Integer;
  MaskChar: Char;
begin
  Result := [];
  for I := 0 to Length(EditMask) - 1 do
  begin
    MaskChar := EditMask[I+1];
    if (MaskChar = dMaskReverse) then
      Result := Result + [miReverseDir]
    else
    if (MaskChar = dMaskLowerCase) and (I < MaskOffset-1) then
    begin
      Result := Result - [miUpperCase] + [miLowerCase];
    end
    else
    if (MaskChar = dMaskUpperCase) and (I < MaskOffset-1) then
    begin
      Result := Result - [miLowerCase];
      if not ((I > 0) and (EditMask[I] = dMaskLowerCase)) then
        Result := Result + [miUpperCase];
    end;
  end;
  if GetMaskCharType(EditMask, MaskOffset) = mcLiteral then
    Result := Result + [miLiteralChar];
end;

function GetFormatSettingsChar(IChar: Char): Char;
begin
  Result := IChar;
  case IChar of
    cMaskTimeSeparator: Result := Char(FormatSettings.TimeSeparator);
    cMaskDateSeparator: Result := Char(FormatSettings.DateSeparator);
  end;
end;

function MaskOffsetToString(const EditMask: String; MaskOffset: Integer): string;
var
  I: Integer;
  CType: TMaskCharType;
begin
  Result := '';
  for I := 1 to MaskOffset do
  begin
    CType := GetMaskCharType(EditMask, I);
    if not (CType in [mcDirective, mcField, mcFieldSeparator]) then
      Result := Result + EditMask[I];
  end;
end;

function MaskOffsetToOffset(const EditMask: String; MaskOffset: Integer): Integer;
begin
  Result := Length(MaskOffsetToString(Editmask, MaskOffset));
end;

function OffsetToMaskOffset(const EditMask: string; Offset: Integer): Integer;
var
  I: Integer;
  Count: Integer;
  MaxChars: Integer;
begin
  MaxChars := MaskOffsetToOffset(EditMask, Length(EditMask));
  if Offset > MaxChars then
  begin
    Result := -1;
    Exit;
  end;

  Result := 0;
  Count := Offset;
  for I := 1 to Length(EditMask) do
  begin
    Inc(Result);
    if not (mcDirective = GetMaskCharType(EditMask, I)) then
    begin
      Dec(Count);
      if Count < 0 then
        Exit;
    end;
  end;
end;

function FormatMaskedText(const EditMask: string; const Value: string; Blank: Char): string;
var
  I: Integer;
  Offset, MaskOffset: Integer;
  CType: TMaskCharType;
  Dir: TMaskInstructions;
begin
  Result := Value;
  Dir := GetMaskInstructions(EditMask, 1);

  if not (miReverseDir in Dir) then
  begin
    { starting at the beginning, insert literal chars in the string
      and add spaces on the end }
    Offset := 1;
    for MaskOffset := 1 to Length(EditMask) do
    begin
      CType := GetMaskCharType(EditMask, MaskOffset);

      if CType in [mcLiteral, mcIntlLiteral] then
      begin
        Result := Copy(Result, 1, Offset - 1) +
          GetFormatSettingsChar(EditMask[MaskOffset]) +
          Copy(Result,  Offset, Length(Result) - Offset + 1);
        Inc(Offset);
      end
      else if CType in [mcMask, mcMaskOpt] then
      begin
        if Offset > Length(Result) then
          Result := Result + Blank;
        Inc(Offset);
      end;
    end;
  end
  else
  begin
      { starting at the end, insert literal chars in the string
        and add spaces at the beginning }
    Offset := Length(Result);
    for I := 0 to (Length(EditMask) - 1) do
    begin
      MaskOffset := Length(EditMask) - I;
      CType := GetMaskCharType(EditMask, MaskOffset);

      if CType in [mcLiteral, mcIntlLiteral] then
      begin
        Result := Copy(Result, 1, Offset) +
               GetFormatSettingsChar(EditMask[MaskOffset]) +
               Copy(Result, Offset + 1, Length(Result) - Offset);
      end
      else if CType in [mcMask, mcMaskOpt] then
      begin
        if Offset < 1 then
          Result := Blank + Result
        else
          Dec(Offset);
      end;
    end;
  end;
end;

function IsLiteralChar(const EditMask: string; Offset: Integer): Boolean;
var
  MaskOffset: Integer;
  CType: TMaskCharType;
begin
  Result := False;
  MaskOffset := OffsetToMaskOffset(EditMask, Offset);
  if MaskOffset >= 0 then
  begin
    CType := GetMaskCharType(EditMask, MaskOffset);
    Result := CType in [mcLiteral, mcIntlLiteral];
  end;
end;

function GetMaskBlank(const EditMask: string): Char;
begin
  Result := mskBlank;
  if Length(EditMask) >= 4 then
  begin
    if (GetMaskCharType(EditMask, Length(EditMask) - 1) = mcFieldSeparator) then
    begin
      if (GetMaskCharType(EditMask, Length(EditMask) - 2) = mcFieldSeparator) or
        (GetMaskCharType(EditMask, Length(EditMask) - 3) = mcFieldSeparator) then
      begin
        Result := EditMask[Length(EditMask)];
      end;
    end;
  end;
end;

{ TCustomMaskEdit }

function TCustomMaskEdit.AddEditFormat(const Value: string;
  Active: Boolean): string;
begin
  if not Active then
    Result := FormatMaskedText(EditMask, Value, ' ')
  else
    Result := FormatMaskedText(EditMask, Value, FMaskBlank);
end;

procedure TCustomMaskEdit.ArrowKeys(CharCode: Word; Shift: TShiftState);
var
  ASelStart, ASelStop : Integer;
begin
  if (ssCtrl in Shift) then Exit;
  GetSel(ASelStart, ASelStop);
  if (ssShift in Shift) then
  begin
    if (CharCode = VK_RIGHT) then
    begin
      Inc(FCaretPos);
      if (ASelStop = ASelStart + 1) then
      begin
        SetSel(ASelStart, ASelStop);  {reset caret to end of string}
        Inc(FCaretPos);
      end;
      if FCaretPos > FMaxChars then FCaretPos := FMaxChars;
    end
    else  {if (CharCode = VK_LEFT) then}
    begin
      Dec(FCaretPos);
      if (ASelStop = ASelStart + 2) and
        (FCaretPos > ASelStart) then
      begin
        SetSel(ASelStart + 1, ASelStart + 1);  {reset caret to show up at start}
        Dec(FCaretPos);
      end;
      if FCaretPos < 0 then FCaretPos := 0;
    end;
  end
  else
  begin
    if (ASelStop - ASelStart) > 1 then
    begin
      if ASelStop = FCaretPos then
        Dec(FCaretPos);
      SetCursor(FCaretPos);
    end
    else if (CharCode = VK_LEFT) then
      CursorDec(ASelStart)
    else   { if (CharCode = VK_RIGHT) then  }
    begin
      if ASelStop = ASelStart then
        SetCursor(ASelStart)
      else
        CursorInc(ASelStart, 1);
    end;
  end;

end;

function TCustomMaskEdit.CharKeys(var CharCode: Char): Boolean;
var
  ASelStart, ASelStop : Integer;
  Txt: string;
begin
  Result := False;

  GetSel(ASelStart, ASelStop);

  if (ASelStop - ASelStart) > 1 then
  begin
    DeleteKeys(VK_DELETE);
    ASelStart := GetNextEditChar(ASelStart);
    SetCursor(ASelStart);
  end;

  Result := InputChar(CharCode, ASelStart);

  if Result then
  begin
    Txt := CharCode;

    Text := Copy(Text, 1, ASelStart) + Txt + Copy(Text, ASelStop + 1, Length(Text));

    CursorInc(ASelStart, 1);
  end;
end;

procedure TCustomMaskEdit.CheckCursor;
var
  ASelStart, ASelStop: Integer;
begin
  if not HandleAllocated then Exit;
  if (IsMasked) then
  begin
    GetSel(ASelStart, ASelStop);
    if ASelStart = ASelStop then
      SetCursor(ASelStart);
  end;
end;

procedure TCustomMaskEdit.CreateInitialize;
begin
  inherited;
  Height := 25;
end;

function TCustomMaskEdit.CursorDec(CursorPos: Integer): integer;
var
  nuPos: Integer;
begin
  Result := 0;

  if CursorPos > 0 then
  begin
    nuPos := CursorPos;
    Dec(nuPos);
    nuPos := GetPriorEditChar(nuPos);
    SetCursor(NuPos);
    Result := NuPos;
  end;
end;

procedure TCustomMaskEdit.CursorInc(CursorPos, Incr: Integer);
var
  NuPos: Integer;
begin
  if (CursorPos < Length(Text)) then
  begin
    NuPos := CursorPos + Incr;
    NuPos := GetNextEditChar(NuPos);
    if IsLiteralChar(EditMask, nuPos) then
      NuPos := CursorPos;
    SetCursor(NuPos);
  end;
end;

procedure TCustomMaskEdit.DeleteKeys(CharCode: Word);
var
  ASelStart, ASelStop: Integer;
  Str, Orig: string;
begin
  GetSel(ASelStart, ASelStop);

  if (ASelStop - ASelStart) < 1 then Exit;

  Str := Text;
  Orig := Text;

  DeleteSelection(Str, ASelStart, ASelStop - ASelStart);

  Str := Copy(Str, ASelStart + 1, ASelStop - ASelStart);

  Str := Copy(Orig, 1, ASelStart) + Str + Copy(Orig, ASelStop + 1, Length(Orig));

  Text := Str;

  SetCursor(ASelStart);
  Change;
end;

function TCustomMaskEdit.DeleteSelection(var Value: string; Offset,
  Len: Integer): Boolean;
var
  EndDel: Integer;
  StrOffset, MaskOffset, Temp: Integer;
  CType: TMaskCharType;
begin
  Result := True;
  if Len = 0 then Exit;

  StrOffset := Offset + 1;
  EndDel := StrOffset + Len;
  Temp := OffsetToMaskOffset(EditMask, Offset);
  if Temp < 0 then  Exit;
  for MaskOffset := Temp to Length(EditMask) do
  begin
    CType := GetMaskCharType(EditMask, MaskOffset);
    if CType in [mcLiteral, mcIntlLiteral] then
      Inc(StrOffset)
    else if CType in [mcMask, mcMaskOpt] then
    begin
      Value[StrOffset] := FMaskBlank;
      Inc(StrOffset);
    end;
    if StrOffset >= EndDel then Break;
  end;
end;

procedure TCustomMaskEdit.DoEnter;
begin
  inherited;
  SelStart := 0;
  SelLength := 1;
end;

function TCustomMaskEdit.DoInputChar(var NewChar: Char;
  MaskOffset: Integer): Boolean;
var
  Instr: TMaskInstructions;
  Str: string;
  CType: TMaskCharType;

begin
  Result := True;
  CType := GetMaskCharType(EditMask, MaskOffset);
  if CType in [mcLiteral, mcIntlLiteral] then
    NewChar := GetFormatSettingsChar(EditMask[MaskOffset])
  else
  begin
    Instr := GetMaskInstructions(EditMask, MaskOffset);
    case EditMask[MaskOffset] of
      cMaskNumeric, cMaskNumericOpt:
        begin
          if not ((NewChar >= '0') and (NewChar <= '9')) then
            Result := False;
        end;
      cMaskNumSymOpt:
        begin
          if not (((NewChar >= '0') and (NewChar <= '9')) or
                 (NewChar = ' ') or(NewChar = '+') or(NewChar = '-')) then
            Result := False;
        end;
      cMaskAscii, cMaskAsciiOpt:
        begin
          if IsCharAlpha(NewChar) then
          begin
            Str := ' ';
            Str[1] := NewChar;
            if (miUpperCase in Instr)  then
              Str := UpperCase(Str)
            else if miLowerCase in Instr then
              Str := LowerCase(Str);
            NewChar := Str[1];
          end;
        end;
      cMaskAlpha, cMaskAlphaOpt, cMaskAlphaNum, cMaskAlphaNumOpt:
        begin
          Str := ' ';
          Str[1] := NewChar;
          if not IsCharAlpha(NewChar) then
          begin
            Result := False;
            if ((EditMask[MaskOffset] = cMaskAlphaNum) or
                (EditMask[MaskOffset] = cMaskAlphaNumOpt)) and
                (IsCharAlphaNumeric(NewChar)) then
              Result := True;
          end
          else if miUpperCase in Instr then
            Str := UpperCase(Str)
          else if miLowerCase in Instr then
            Str := LowerCase(Str);
          NewChar := Str[1];
        end;
    end;
  end;
end;

function TCustomMaskEdit.EditCanModify: boolean;
begin
  Result := not ReadOnly;
end;

function TCustomMaskEdit.FindLiteralChar(MaskOffset: Integer;
  InChar: Char): Integer;
var
  CType: TMaskCharType;
  LitChar: Char;
begin
  Result := -1;
  while MaskOffset < Length(EditMask) do
  begin
    Inc(MaskOffset);
    CType := GetMaskCharType(EditMask, MaskOffset);
    if CType in [mcLiteral, mcIntlLiteral] then
    begin
      LitChar := EditMask[MaskOffset];
      if CType = mcIntlLiteral then
        LitChar := GetFormatSettingsChar(LitChar);
      if LitChar = InChar then
        Result := MaskOffset;
      Exit;
    end;
  end;
end;

function TCustomMaskEdit.GetEditText: string;
begin
  Result := RemoveEditFormat(inherited Text);
end;

function TCustomMaskEdit.GetFirstEditChar: Integer;
begin
  Result := 0;
  if IsMasked then
    Result := GetNextEditChar(0);
end;

function TCustomMaskEdit.GetLastEditChar: Integer;
begin
  Result := GetMaxChars;
  if IsMasked then
    Result := GetPriorEditChar(Result - 1);
end;

function TCustomMaskEdit.GetMasked: Boolean;
begin
  Result := EditMask <> '';
end;

function TCustomMaskEdit.GetMaxChars: Integer;
begin
  if IsMasked then
    Result := FMaxChars
  else
    Result := GetTextLen;
end;

function TCustomMaskEdit.GetNextEditChar(Offset: Integer): Integer;
begin
  Result := Offset;
  while (Result < FMaxChars) and (IsLiteralChar(EditMask, Result)) do
    Inc(Result);
end;

function TCustomMaskEdit.GetPriorEditChar(Offset: Integer): Integer;
begin
  Result := Offset;
  while (Result >= 0) and (IsLiteralChar(EditMask, Result)) do
    Dec(Result);
  if Result < 0 then
    Result := GetNextEditChar(Result);
end;

procedure TCustomMaskEdit.GetSel(var ASelStart, ASelStop: Integer);
begin
  ASelStart := SelStart;
  ASelStop := SelStart + SelLength;
end;

function TCustomMaskEdit.GetTextLen: integer;
begin
  Result := Length(Text);
end;

procedure TCustomMaskEdit.HomeEndKeys(CharCode: Word; Shift: TShiftState);
var
  ASelStart, ASelStop : Integer;
begin
  GetSel(ASelStart, ASelStop);
  if (CharCode = VK_HOME) then
  begin
    if (ssShift in Shift) then
    begin
      if (ASelStart <> FCaretPos) and (ASelStop <> (ASelStart + 1)) then
        ASelStop := ASelStart + 1;
      SetSel(0, ASelStop);
      CheckCursor;
    end
    else
      SetCursor(0);
    FCaretPos := 0;
  end
  else
  begin
    if (ssShift in Shift) then
    begin
      if (ASelStop <> FCaretPos) and (ASelStop <> (ASelStart + 1)) then
        ASelStart := ASelStop - 1;
      SetSel(ASelStart, FMaxChars);
      CheckCursor;
    end
    else
      SetCursor(FMaxChars - 1);
    FCaretPos := FMaxChars;
  end;

end;

function TCustomMaskEdit.InputChar(var NewChar: Char; Offset: Integer): Boolean;
var
  MaskOffset: Integer;
  CType: TMaskCharType;
  InChar: Char;
begin
  Result := True;

  if EditMask <> '' then
  begin
    Result := False;
    if Offset < FMaxChars then
    begin
      MaskOffset := OffsetToMaskOffset(EditMask, Offset);
      if MaskOffset >= 0 then
      begin
        CType := GetMaskCharType(EditMask, MaskOffset);
        InChar := NewChar;
        Result := DoInputChar(NewChar, MaskOffset);
        if not Result and (CType in [mcMask, mcMaskOpt]) then
        begin
          MaskOffset := FindLiteralChar (MaskOffset, InChar);
          if MaskOffset > 0 then
          begin
            MaskOffset := MaskOffsetToOffset(EditMask, MaskOffset);
            SetCursor (MaskOffset);
            Exit;
          end;
        end;
      end;
    end;
  end;
  if not Result and (NewChar <> #13) and (NewChar <> #27) then
  begin
    MessageBeep(0)
  end
  else
    Change;
end;

procedure TCustomMaskEdit.KeyDown(var Key: Word; Shift: TShiftState);
begin
  inherited KeyDown(Key, Shift);

  if (Key in [VK_LEFT, VK_RIGHT, VK_HOME, VK_END]) then
  begin
    PreventDefault;
    StopPropagation;
  end;

  if IsMasked and (Key <> 0) and not (ssAlt in Shift) then
  begin
    if not (Key in [VK_LEFT, VK_RIGHT, VK_HOME, VK_END]) then
    begin
      if SelLength > 1 then
      begin
        SelStart := 0;
        SelLength := 1;
      end;
    end;

    if (Key = VK_LEFT) or(Key = VK_RIGHT) then
    begin
      ArrowKeys(Key, Shift);
      if not ((ssShift in Shift) or (ssCtrl in Shift)) then
        Key := 0;
      Exit;
    end
    else if (Key = VK_UP) or(Key = VK_DOWN) then
    begin
      Key := 0;
      Exit;
    end
    else if (Key = VK_HOME) or(Key = VK_END) then
    begin
      HomeEndKeys(Key, Shift);
      Key := 0;
      Exit;
    end
    else if ((Key = VK_DELETE) and not (ssShift in Shift)) or
      (Key = VK_BACK) then
    begin
      if EditCanModify then
        DeleteKeys(Key);
      Key := 0;
      PreventDefault;
      StopPropagation;
      Exit;
    end;
    CheckCursor;
  end;
end;

procedure TCustomMaskEdit.KeyPress(var Key: Char);
begin
  inherited KeyPress(Key);

  if IsMasked and (Key <> #0) and not CharInSet(Key, [^V, ^X, ^C, #8]) then
  begin
    CharKeys(Key);
    Key := #0;
    PreventDefault;
    StopPropagation;
  end;
end;

procedure TCustomMaskEdit.KeyUp(var Key: Word; Shift: TShiftState);
begin
  inherited KeyUp(Key, Shift);

  if IsMasked and (Key <> 0) then
  begin
    if ((Key = VK_LEFT) or(Key = VK_RIGHT)) and (ssCtrl in Shift) then
      CheckCursor;
  end;
end;

procedure TCustomMaskEdit.Loaded;
begin
  inherited;
  //Text := FEditText;
end;

procedure TCustomMaskEdit.MouseUp(Button: TMouseButton; Shift: TShiftState; X,
  Y: Integer);
begin
  inherited;
end;

procedure TCustomMaskEdit.ReformatText(const NewMask: string);
var
  OldText: string;
begin
  OldText := RemoveEditFormat(EditText);
  FEditMask := NewMask;
  FMaxChars  := MaskOffsetToOffset(EditMask, Length(NewMask));
  FMaskBlank := GetMaskBlank(NewMask);
//  OldText := AddEditFormat(OldText, True);
  EditText := OldText;
end;

function TCustomMaskEdit.RemoveEditFormat(const Value: string): string;
var
  I: Integer;
  OldLen: Integer;
  Offset, MaskOffset: Integer;
  CType: TMaskCharType;
  Dir: TMaskInstructions;
begin
  Offset := 1;
  Result := Value;
  for MaskOffset := 1 to Length(EditMask) do
  begin
    CType := GetMaskCharType(EditMask, MaskOffset);

    if CType in [mcLiteral, mcIntlLiteral] then
      Result := Copy(Result, 1, Offset - 1) +
        Copy(Result, Offset + 1, Length(Result) - Offset);
    if CType in [mcMask, mcMaskOpt] then Inc(Offset);
  end;

  Dir := GetMaskInstructions(EditMask, 1);
  if miReverseDir in Dir then
  begin
    Offset := 1;
    for I := 1 to Length(Result) do
    begin
      if Result[I] = FMaskBlank then
        Inc(Offset)
      else
        break;
    end;
    if Offset <> 1 then
      Result := Copy(Result, Offset, Length(Result) - Offset + 1);
  end
  else begin
    OldLen := Length(Result);
    for I := 1 to OldLen do
    begin
      if Result[OldLen - I + 1] = FMaskBlank then
        SetLength(Result, Length(Result) - 1)
      else Break;
    end;
  end;
  if FMaskBlank <> ' ' then
  begin
    OldLen := Length(Result);
    for I := 1 to OldLen do
    begin
      if Result[I] = FMaskBlank then
        Result[I] := ' ';
      if I > OldLen then Break;
    end;
  end;

end;

procedure TCustomMaskEdit.Reset;
begin
  EditText := FOldValue;
end;

procedure TCustomMaskEdit.SetCursor(Pos: Integer);
begin
  SetSel(Pos,Pos + 1);
end;

procedure TCustomMaskEdit.SetEditMask(const Value: TEditMask);
var
  SelStart, SelStop: Integer;
begin
  if Value <> EditMask then
  begin
    if (csDesigning in ComponentState) and (Value <> '') and
      not (csLoading in ComponentState) then
      EditText := '';

    if HandleAllocated then GetSel(SelStart, SelStop);

    ReformatText(Value);
    Exclude(FMaskState, mStateMasked);

    if EditMask <> '' then
      Include(FMaskState, mStateMasked);

    inherited MaxLength := 0;

    if IsMasked and (FMaxChars > 0) then
    begin
      inherited MaxLength := FMaxChars;
    end;

    if HandleAllocated and Focused and
       not (csDesigning in ComponentState) then
      SetCursor(SelStart);
  end;
end;

procedure TCustomMaskEdit.SetEditText(const Value: string);
begin
  FEditText := AddEditFormat(Value, True);
  inherited Text := FEditText;
end;

procedure TCustomMaskEdit.SetSel(ASelStart, ASelStop: Integer);
begin
  SelStart := ASelStart;
  SelLength := Max(1,ASelStop - ASelStart);
end;

end.
